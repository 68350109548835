import style from './SideMenuBtn.module.css';

import { AiFillMedicineBox, AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";

const iconMap = {
    'SOLICITAR ASO': AiFillMedicineBox,
    'GERAR RELATÓRIO' : RiFileExcel2Line
}

const SideMenuBtn = ({ button, clickFunc, isLoading }) => {

    const IconComponent = iconMap[ button ]

    return(
        <div className={ style.sideMenuBtn } onClick={ clickFunc } >
            <div className={ style.divIcon } >
                { isLoading ? <AiOutlineLoading3Quarters className={ style.spinner } /> : <IconComponent /> }   
            </div>
            <p>{ button }</p>
        </div>
    )

}

export default SideMenuBtn;