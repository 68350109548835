import "./NavBarReps.css"

const NavBarReps = ({ state, changeState }) => {

    return(

        <div className="nav_bar" >
            <img src="/images/Logo.png" id="logo_palmont" alt="logo_palmont" />
            <button id="newRepBtn" onClick={ () => changeState( !state ) } > ADICIONAR REPUBLICA </button>
        </div>

    )

}

export default NavBarReps;