import { formatCPF } from '../../Funcoes/FormatCPF';
import './AdmissaoFilter.css';

import { useEffect, useState } from 'react';

const AdmissaoFilter = ({ tabelaValues, filterTable }) => {

    const [ obraFilter, setObraFilter ] = useState('');
    const [ cpfFilter, setCpfFilter ] = useState('');
    const [ nomeFilter, setNomeFilter ] = useState('');
    const [ funcaoFilter, setFuncaoFilter ] = useState('');
    const [ statusFilter, setStatusFilter ] = useState('');
    const [ desistFilter, setDesistFilter ] = useState('');
    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');

    useEffect(() => {

        filterTable( obraFilter, cpfFilter, nomeFilter, funcaoFilter, statusFilter, desistFilter, startDate , endDate )

    },[ obraFilter, cpfFilter, nomeFilter, funcaoFilter, statusFilter, desistFilter, startDate, endDate ]);

    return(
        <div className='headerAdmissao' >

            <div className='filterRow1' >

                <select value={ obraFilter } onChange={ ( e ) => {

                    let { value } = e.target;

                    setObraFilter( value );

                } } >

                    <option value={''} >CENTRO CUSTO...</option>

                    {

                        [ ...new Set( tabelaValues.map(obj => obj.centro_custo).filter(centro_custo => centro_custo !== null) ) ].sort().map(centro_custo => (
                            <option value={centro_custo} key={centro_custo}>{centro_custo}</option>
                        ))

                    }

                </select>

                <input type='text' placeholder='CPF:' value={ cpfFilter } onInput={ ( e ) => {

                    let { value } = e.target
                    value = value.replace(/\D/g, '');
                    value = formatCPF( value )

                    setCpfFilter( value )

                } } />

                <input type='text' placeholder='NOME:' value={ nomeFilter } onInput={ ( e ) => {

                    let { value } = e.target
                    value = value.toUpperCase();

                    setNomeFilter( value )

                } } />

            </div>

            <div className='filterRow2' >
                <select value={ funcaoFilter } onChange={ ( e ) => { 

                    let { value } = e.target

                    setFuncaoFilter( value );

                } }  >
                    <option value={''} >FUNCÃO...</option>

                    { 
                        [ ...new Set( tabelaValues.map(obj => obj.vaga_funcao).filter(funcao => funcao !== null) ) ].sort().map(funcao => (
                            <option value={funcao} key={funcao}>{funcao}</option>
                        )) 
                    }

                </select>
                <select value={ statusFilter } onChange={ ( e ) => {

                    let { value } = e.target;

                    setStatusFilter( value );

                } } >
                    <option value={''} >STATUS...</option>

                    { [ ...new Set( tabelaValues.map( obj => obj.last_key ).filter( status => status !== null ) ) ].sort().map( status => (
                        
                        <option value={ status } key={ status } >{ status }</option>

                    ) ) }

                </select>
                <select value={ desistFilter } onChange={ ( e ) => {

                    let { value } = e.target;

                    setDesistFilter( value );

                } } >
                    <option value={''} >DESISTENTE...</option>
                    <option value={'SIM'} >SIM</option>
                    <option value={'NAO'} >NÃO</option>
                </select>

                <div className='filterDatas' >

                    <input type='date' value={ startDate } onChange={ ( e ) => {

                        let { value } = e.target;

                        setStartDate( value );

                    } } />

                    <input type='date' value={ endDate } onChange={ ( e ) => {

                        let { value } = e.target;

                        setEndDate( value );

                    } } />

                </div>
            </div>
        </div>
    )

}

export default AdmissaoFilter;