import './FolhaPagSquare.css'

import { formatarData } from '../../Funcoes/FormatarData';
import { formatValorMoney } from '../../Funcoes/FormatValorMoney';
import { MdDelete } from "react-icons/md";
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';

const FolhaPagSquare = ({ dados, usuario, deleteTableRow }) => {

    const [ idDel, setIdDel ] = useState( null );
    const [ origemDel, setOrigemDel ] = useState( null );
    const [ dataAtual, setDataAtual ] = useState( null );
    const [ tempId, setTempId ] = useState( null );

    const[ readyToPush, setReadyToPush ] = useState( false );

    const delRow = ( id, origem, temp_id ) => {

        setIdDel( id );
        setOrigemDel( origem );
        setTempId( temp_id )

        let data = new Date();
        setDataAtual( format( data, "yyyy-MM-dd HH:mm:ss" ) )

        setReadyToPush( true );

    }

    useEffect(() => {

        if( readyToPush ){

            const deleteRow = async () => {

                try{

                    const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/DelPagRow/', {
                        id : idDel,
                        origem : origemDel,
                        data_atual : dataAtual,
                        user : usuario
                    })

                    if( data.STATUS === "SUCCESS" ){

                        deleteTableRow( tempId )

                    }

                }catch( error ){

                    console.log( error )

                }

            }

            deleteRow();

            setReadyToPush( false );

        }

    }, [ readyToPush ])

    return(
        <div className='folhaPagSquare' >
            <table>
                <thead>
                    <tr>
                        <th>VALOR</th>
                        <th>MOTIVO</th>
                        <th>MATRICULA</th>
                        <th>COLABORADOR</th>
                        <th>DATA</th>
                        <th>CENTRO CUSTO</th>
                        <th>OBSERVAÇÕES</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    { dados.map( ( obj ) => (

                        <tr>

                            <td>R$ { formatValorMoney( obj.valor ) }</td>
                            <td>{ obj.motivo }</td>
                            <td>{ obj.matricula }</td>
                            <td>{ obj.colaborador }</td>
                            <td>{ formatarData( obj.data ) }</td>
                            <td>{ obj.centro_custo }</td>
                            <td>{ obj.observacoes ? obj.observacoes : ' - ' }</td>
                            <td>{ obj.origem !== 'BENEFICIOS' ?
                                <MdDelete className='delRow' onClick={ () => { delRow( obj.id, obj.origem, obj.temp_id ) } } />
                                : 
                                '-'
                            }</td>

                        </tr>

                    ) ) }
                </tbody>
            </table>
        </div>
    )

}

export default FolhaPagSquare;