import React, { useEffect } from "react";
import NavBar from "../Componentes/NavBar";
import ColabInfoContent from "../Componentes/ColabInfoContent";
import SidebarColab from "../Componentes/SidebarColab";
import OverlayNewFolga from "../Componentes/OverlayNewFolga";

import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import axios from "axios";
import Cookies from 'js-cookie';
import OverlayListBenefits from "../Componentes/OverlayListBenefits";
import OverlayAddBenefits from "../Componentes/OverlayAddBenefits";

const ColabInfo = () => {

    const [ sideBar, SetSideBar ] = useState( false )
    const [ square, setSquare ] = useState( false )

    const [ user, setUser ] = useState('')
    const [ stateUser, setStateUser ] = useState( false )

    const [ benefitsListState, setBenefitsListState ] = useState( false )
    const [ benefitsAddState, setBenefitsAddState ] = useState( false )

    const token = Cookies.get('token')

    let { matricula } = useParams();

    const [ colab, setColab ] = useState([]);

    const [ formValues, setFormValues ] = useState({
        'beneficio' : '',
        'valor' : '',
        'matricula' : matricula,
        'user' : '',
        'data' : '',
        'id' : '',
        'func' : '',
        'centro_custo' : ''
    })

    useEffect(() => {

        const getData = async () => {

            try{
    
                const { data } = await axios.get(`https://sistemaintegrado.palmont.com.br/Painel/assets/php/getColabInfo.php?matricula=${matricula}`)

                setColab( data )
                setFormValues( prevValues => ({

                    ...prevValues,
                    'centro_custo' : data[0].OBRA

                }))
    
            }catch(error){
    
                console.log( error )
    
            }
    
        }
    
        getData();

    }, [ matricula ]);

    useEffect( () => {

        const authUser = async () =>{

            if( token ){
        
                try{
    
                    const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/AuthToken/', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
    
                    if( data.STATUS === "SUCCESS" ){
    
                        setStateUser( true )
                        setUser( data.USER )
    
                    }
    
                }catch(error){
                    console.log(error);
                }
        
            }else{
        
                return <Navigate to="/Login" replace />;
        
            }

        }

        authUser();

    }, [ token ])

    if( stateUser ){

        return(

            <div className="content" >
    
                <NavBar
                    title="COLABORADOR"
                    state={ sideBar } 
                    changeState={ SetSideBar } 
                />
    
                <ColabInfoContent
                    user={ user } 
                    colab={ colab } 
                />
    
                <SidebarColab
                    state={ sideBar }
                    changeState={ SetSideBar }
                    squareState={ square }
                    changeSquareState={ setSquare }
                    changeBenefitsState={ setBenefitsListState }
                />
    
                <OverlayNewFolga 
                    squareState={ square }
                    changeSquareState={ setSquare } 
                    user={ user } 
                />

                <OverlayListBenefits
                    overlayState={ benefitsListState }
                    changeOverlayState={ setBenefitsListState }
                    setBenefitsAddState={ setBenefitsAddState }
                    setFormValues={ setFormValues }
                    formValues={ formValues }
                />

                <OverlayAddBenefits
                    benefitsAddState={ benefitsAddState }
                    setBenefitsAddState={ setBenefitsAddState }
                    user={ user }
                    formValues={ formValues }
                    setFormValues={ setFormValues }
                />
            </div>
    
        )

    }else{

        return(
            <p> USUARIO NÃO AUTENTICADO </p>
        )

    }

}

export default ColabInfo;