import './AdmissaoBtnSidebar.css';

const AdmissaoBtnSidebar = ({ name, icon, click }) => {

    return(
        <div className='SidebarContainerAdm' onClick={ click } >
            <div className='containerIcon' > { icon } </div>
            <p>{ name }</p>
        </div>
    )

}

export default AdmissaoBtnSidebar;