import LoadingScreen from "../Componentes/LoadingScreen"
import NavBar from "../Componentes/NavBar"
import AdmissaoBody from "../Componentes/AdmissaoBody"
import SideBarAdmissao from "../Componentes/SideBarAdmissao"
import OverlayNewAdm from "../Componentes/OverlayNewAdm"

import Cookies from "js-cookie"

import { useState, useEffect } from "react"
import { Navigate } from "react-router-dom"
import { UseAuth } from "../Funcoes/UseAuth"
import axios from "axios"

const Admissao = () => {

    const [ loadingScreen, setLoadingScreen ] = useState( true );
    const [ auth, setAuth ] = useState( null );

    const [ overlayState, setOverlayState ] = useState( false );
    const [ overlayNew, setOverlayNew ] = useState( false );

    const token = Cookies.get('token')

    const [ formValues, setFormValues ] = useState({
        'nome' : '',
        'cpf' : '',
        'funcao' : '',
        'desistencia' : '',
        'prev_chegada' : '',
        'obs' : '',
        'telefone' : '',
        'estado' : '',
        'municip' : '',
        'receb_curr' : '',
        'data_contato' : '',
        'data_chegada' : '',
        'data_exame' : '',
        'doc_admissao' : '',
        'doc_sesmt' : '',
        'admissao' : '',
        'cadastro_portal' : '',
        'integracao' : '',
        'entrada_site' : '',
        'num_uniforme' : '',
        'num_calcado' : 0,
        'user' : '',
        'preenchido' : '',
        'centro_custo' : ''
    })

    const [ requestStatus, setRequestStatus ] = useState('')

    const [ admissions, setAdimissions ] = useState([]);
    const [ admissionsFilter, setAdimissionsFilter ] = useState([]);

    const [ loadingAdmission, setLoadingAdmission ] = useState( true );

    useEffect(() => {

        const fetch_admissions = async () => {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetAdmissions/');
            
            setAdimissions( data );
            setAdimissionsFilter( data );

        }

        fetch_admissions();

    },[])

    useEffect(() => {

        if( admissionsFilter.length > 0 ){

            setLoadingAdmission( false );

        }

    }, [ admissionsFilter ])

    useEffect( () => {

        const fetchUser = async () => {

            try{

                setAuth( await UseAuth( token ) )

            }catch( error ){

                console.log( error )

            }finally{

                setLoadingScreen( false )

            }

        }

        fetchUser();

    }, [ token ])

    const handleFilter = ( obraFilter, cpfFilter, nomeFilter, funcaoFilter, statusFilter, desistFilter, startDate, endDate ) => {

        const filtered = admissions.filter( item =>  {

            const itemDate = item.last_date ? new Date( item.last_date + ' 00:00:00' ) : null;
            const cpf = cpfFilter.replace(/\D/g, '');

            const start_date = new Date( startDate + ' 00:00:00' )
            const end_date = new Date( endDate + ' 00:00:00' )

            const obra_filter = !obraFilter || item.centro_custo === obraFilter;
            const cpf_filter = !cpfFilter || item.cpf.includes( cpf );
            const nome_filter = !nomeFilter || item.nome.includes( nomeFilter );
            const funcao_filter = !funcaoFilter || item.vaga_funcao === funcaoFilter;
            const status_filter = !statusFilter || item.last_key === statusFilter;

            const desist_filter = !desistFilter ||
                                        ( desistFilter === 'SIM' && item.desistencia !== null ) ||
                                        ( desistFilter === 'NAO' && item.desistencia === null )

            const start_filter = !startDate || itemDate >= start_date;
            const end_filter = !endDate || itemDate <= end_date;

            return obra_filter && cpf_filter && nome_filter && funcao_filter && status_filter && desist_filter && start_filter && end_filter;

        })

        setAdimissionsFilter( filtered );

    }


    if( loadingScreen ){

        return( <LoadingScreen /> )

    }

    if( !auth || auth.STATUS !== "SUCCESS" ){

        Cookies.remove('token')
        return <Navigate to="/Login" replace />

    }

    return(

        <>
        
            <NavBar state={ overlayState } changeState={ setOverlayState } title="PROCESSO ADM" />

            <AdmissaoBody 
                tabelaValues={ admissionsFilter }
                filterTable={ handleFilter }
                loadAdmission={ loadingAdmission } 
                formValues={ formValues } 
                setFormValues={ setFormValues } 
                changeState={ setOverlayNew }
                user={ auth }
                setRequestStatus={ setRequestStatus }
            />

            <SideBarAdmissao 
                user={ auth } 
                overlayState={ overlayState }
                setOverlayState={ setOverlayState }
                changeOverlayNew={ setOverlayNew }
                setFormValues={ setFormValues }
                setRequestStatus={ setRequestStatus }
            />

            <OverlayNewAdm
                state={ overlayNew }
                changeState={ setOverlayNew }
                formValues={ formValues }
                changeValues={ setFormValues }
                requestStatus={ requestStatus }
            />

        </>

    )

}

export default Admissao