import LoadingScreen from "../Componentes/LoadingScreen";

import Cookies from "js-cookie";

import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UseAuth } from "../Funcoes/UseAuth";
import NavBar from "../Componentes/NavBar";
import PagamentosBody from "../Componentes/PagamentosBody";
import SideBarPagamentos from "../Componentes/SideBarPagamentos";
import OverlayPagamento from "../Componentes/OverlayPagamento";
import axios from "axios";

const Pagamento = () => {

    const [ loadingScreen, setLoadingScreen ] = useState( true )
    const [ auth, setAuth ] = useState( null )

    const [ formValues, setFormValues ] = useState({

        obra : null,
        motivo : null,
        matricula : null,
        valor : "0.00",
        data : null,
        user : null

    })

    const motivos = [

        'DESCONTO ALMOXARIFADO',
        'DIARIA DE VIAGEM',
        'FOLGA DE DOMINGO',
        'REEMBOLSO'

    ]

    const [ folhaPagamento, setFolhaPagamento ] = useState([])
    const [ FilteredFolha, setFilteredFolha ] = useState([])

    const [ overlayState, setOverlayState ] = useState( false )
    const [ overlayPag, setOverlayPag ] = useState( false )

    const token = Cookies.get('token')

    useEffect( () => {

        const fetchUser = async () => {

            try{

                setAuth( await UseAuth( token ) )

            }catch( error ){

                console.log( error )

            }finally{

                setLoadingScreen( false )

            }

        }

        fetchUser();

    }, [ token ])
    
    useEffect(() => {

        const fetchFolhaValues = async () => {

            try{

                const { data } = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/API/GetFolhaPag/")
                setFolhaPagamento( data )
                setFilteredFolha( data )

                console.log( data )

            }catch( error ){

                console.log( error )

            }

        }

        fetchFolhaValues();

    }, [])

    const handleFilter = ( startDate, endDate, obraFilter, motivoFilter ) => {

        const filtered = folhaPagamento.filter(item => {

            const itemDate = new Date(item.data);

            const start_filter = !startDate || itemDate >= new Date(startDate);
            const end_filter = !endDate || itemDate <= new Date(endDate);
            const obra_filter = !obraFilter || item.centro_custo === obraFilter
            const motivo_filter = !motivoFilter || item.motivo === motivoFilter
    
            return start_filter && end_filter && obra_filter && motivo_filter;

        });
    
        setFilteredFolha( filtered );

    };

    if( loadingScreen ){

        return( <LoadingScreen /> );

    }

    if( !auth || auth.STATUS !== "SUCCESS" ){

        Cookies.remove('token')
        return <Navigate to="/Login" replace />

    }

    if( auth.ACCESS_LVL !== 1 ){

        return <Navigate to="/Home" replace />

    }

    return(
        <div className="content" >

            <NavBar

                state={ overlayState }
                changeState={ setOverlayState }

            />
                
            <PagamentosBody

                overlayState={ overlayState }
                changeOverlayState={ setOverlayState }
                onFilter={ handleFilter }
                dados={ FilteredFolha }

            />

            <SideBarPagamentos 

                overlayState={ overlayState } 
                changeOverlayState={ setOverlayState }
                changeOverlayPag={ setOverlayPag }
                dados={ FilteredFolha }

            />

            <OverlayPagamento

                overlayState={ overlayState }
                changeOverlayState={ setOverlayState }
                overlayPag={ overlayPag }
                changeOverlayPag={ setOverlayPag }
                formValues={ formValues }
                setFormValues={ setFormValues }
                user={ auth.USER }
                motivos = { motivos }
            />

        </div>
    )

}

export default Pagamento;