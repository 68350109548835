import axios from 'axios';
import './OverlayDelUser.css';

import { useState } from 'react';

const OverlayDelUser = ({ overlayDel, changeOverlayDel, usuario }) => {

    const [ btnValue, setBtnValue ] = useState("SIM");
    const [ disabledBtn, setDisabledBtn ] = useState( false );

    const delete_user = async () => {

        setBtnValue("CARREGANDO...");
        setDisabledBtn( true );

        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/DeleteUser/', {
                id_user : usuario
            })

            if( data.STATUS === "SUCCESS" ){

                window.location.reload();

            }

        }catch( error ){

            console.log( error );

        }finally{

            setDisabledBtn( false );
            setBtnValue("SIM");
            
        }

    }

    return(
        <div className={ overlayDel ? 'overlayDelUser show' : 'overlayDelUser hide' } onClick={ () => { 
            changeOverlayDel( false )
        } } >
            <div className='containerDel' onClick={ ( e ) => {
                e.stopPropagation();
            } } >
                <p>REALMENTE DESEJA APAGAR O USUARIO { usuario } ?</p>
                <div className='contBtns' >

                    <button className='btnSim' disabled={ disabledBtn } onClick={ () => {

                        delete_user();

                    } } >{ btnValue }</button>

                    <button className='btnNao' onClick={ () => {
                        changeOverlayDel( false );
                    } } >NÃO</button>

                </div>
            </div>
        </div>
    )

}

export default OverlayDelUser;