import './OverlayAddObra.css';

import { useState, useEffect } from 'react';
import axios from 'axios';

const OverlayAddObra = ({ usuario, overlayAdd, setOverlayAdd }) => {

    const [ obras, setObras ] = useState([]);
    const [ selectedObra, setSelectedObra ] = useState('');
    const [ saveBtn, setSaveBtn ] = useState('SALVAR');
    const [ disabled, setDisabled ] = useState( false );

    useEffect( () => {

        const getObras = async () => {

            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetAllObras/');
                setObras( data );

            }catch( error ){

                console.log( error );

            }

        }

        getObras();

    }, [])

    useEffect( () => {

        setSelectedObra('');

    }, [ overlayAdd ])

    const addNewObra = async ( e ) => {

        e.preventDefault();
        
        if( selectedObra ){

            setSaveBtn('SALVANDO...');
            setDisabled( true );

            try{

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/SaveNewPermission/', {
                    user : usuario,
                    centro_custo : selectedObra
                })

                if( data.STATUS === "SUCCESS" ){

                    window.location.reload();

                }

            }catch( error ){

                console.log( error );

            }finally{

                setSaveBtn('SALVAR');
                setDisabled( false );

            }

        }else{

            alert( 'SELECIONE UM CENTRO DE CUSTO!!!' )

        }

    }


    return <div className={ overlayAdd ? 'overlayAddObra show' : 'overlayAddObra hide' } onClick={ () => { setOverlayAdd( false ) } } >
        <div className='squareNewObra' onClick={ ( e ) => { e.stopPropagation() } } >
            <p>LIBERAR ACESSO Á OBRAS</p>

            <form>

                <select value={ selectedObra } onChange={ ( e ) => {

                    let { value } = e.target;
                    setSelectedObra( value );

                } } >
                    <option value={''} >SELECIONE...</option>
                    { obras.map( obj => (
                        <option value={ obj.OBRA } key={ obj.Centro_Custo } >{ obj.OBRA }</option>
                    ) ) }
                </select>

                <button onClick={ addNewObra } disabled={ disabled } >{ saveBtn }</button>

            </form>
        </div>
    </div>

}

export default OverlayAddObra;