import React from "react";

import { formatCPF } from "../../Funcoes/FormatCPF";
import { formatDate } from "../../Funcoes/FormatData";
import { formatarData } from "../../Funcoes/FormatarData";

const TableBody = ({ data, final }) => {

    const navigate = ( matricula ) => {

        console.log( matricula )
        window.open(`/Colab/${ matricula }`, '_blank');

    };

    return (
        data.slice(0, final).map(item => (
            <tr key={item.MATRICULA} onClick={ () => { navigate( item.MATRICULA )}}>
                <td>{item.NOME}</td>
                <td>{ formatCPF( item.CPF ) }</td>
                <td>{item.MATRICULA}</td>
                <td>{ formatDate( item.DATA_ADMISSAO ) }</td>
                <td>{item.DATA_ASO === null ? '-' : formatarData( item.DATA_ASO ) }</td>
                <td>{item.OBRA}</td>
                <td>{item.CONTRATO === null ? '-' : item.CONTRATO }</td>
                <td>{item.FUNCAO}</td>
                <td>{item.MAOOBRA}</td>
                <td>{item.STATUS}</td>
            </tr>
        ))
    );
}

export default TableBody;