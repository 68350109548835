import { format } from 'date-fns';

export function formatarData(dateString) {
    if (!dateString || (dateString.length !== 10 && dateString.length !== 19)) { 
        // Verifica se o comprimento é 10 (yyyy-mm-dd) ou 19 (yyyy-mm-dd hh:mm:ss)
        throw new Error('Invalid date string format. Expected format: yyyy-mm-dd or yyyy-mm-dd hh:mm:ss');
    }

    const dateParts = dateString.split(' '); // Separa a data da hora, se houver
    const dateComponent = dateParts[0]; // Parte da data no formato yyyy-mm-dd
    const timeComponent = dateParts[1] || ''; // Parte da hora no formato hh:mm:ss, se houver

    const [year, month, day] = dateComponent.split('-').map(Number);
    let hours = 0, minutes = 0, seconds = 0;

    if (timeComponent) {
        const timeParts = timeComponent.split(':');
        if (timeParts.length !== 3) {
            throw new Error('Invalid time format. Expected format: hh:mm:ss');
        }
        [hours, minutes, seconds] = timeParts.map(Number);
    }

    // Ajuste do mês (em JavaScript, janeiro é 0 e dezembro é 11)
    const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);

    if (isNaN(dateObject.getTime())) {
        throw new Error('Invalid date');
    }

    return format(dateObject, 'dd/MM/yyyy');
}