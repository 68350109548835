import style from './FilterSelect.module.css';

const FilterSelect = ({ options, placeholder, value, onChange }) => {

    return(
        <select value={ value } onChange={ onChange } >
            <option value='' >{ placeholder }</option>
            { options.map( opt => (
                <option value={ opt } >{ opt }</option>
            ) ) }
        </select>
    )

}

export default FilterSelect;