import './LoadingScreen.css'

const LoadingScreen = () => {

    return(
        <div className='loadScreen' >
            <img id='loadingLogo' src='/Images/Logo.png' alt='loadingLogo' />
        </div>
    )

}

export default LoadingScreen;