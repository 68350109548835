import './UsersSquare.css';

import { MdDelete, MdEdit } from "react-icons/md";
import { formatCPF } from '../../Funcoes/FormatCPF';

const UsersSquare = ({ data, selectUser, changeStateOverlay, changeOverlayDel, changeOverlayEdit, getUserAccess }) => {

    return(
        <div className='usersSquare' >

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NOME</th>
                        <th>CPF</th>
                        <th>EMAIL</th>
                        <th>PERMISSÃO</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map(( obj ) => (
                            <tr key={ obj.user_id } onClick={ () => {
                                    selectUser( obj.user_id );
                                    getUserAccess( obj.permissao )
                                    changeStateOverlay( true );
                            } } >
                                <td>{ obj.user_id }</td>
                                <td>{ obj.nome }</td>
                                <td>{ formatCPF( obj.cpf ) }</td>
                                <td>{ obj.email.toLowerCase() }</td>
                                <td>{ obj.permissao }</td>
                                <td className='linhaFunc' >{ 
                                    <div className='botoes' >
                                        { <MdEdit className='edit_btn' onClick={ ( e ) => { 
                                            e.stopPropagation();
                                            selectUser( obj.user_id );
                                            getUserAccess( obj.permissao )
                                            changeOverlayEdit( true );
                                        } } /> }
                                        { <MdDelete className='delete_btn' onClick={ ( e ) => {
                                            e.stopPropagation();
                                            selectUser( obj.user_id );
                                            changeOverlayDel( true );
                                        } } /> }
                                    </div>
                                }</td>
                            </tr>
                        ) )
                    }
                </tbody>
            </table>

        </div>
    )

}

export default UsersSquare;