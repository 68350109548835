import React from 'react';

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>NOME</th>
        <th>CPF</th>
        <th>MATRICULA</th>
        <th>ADMISSÃO</th>
        <th>ASO</th>
        <th>CENTRO CUSTO</th>
        <th>CONTRATO</th>
        <th>FUNÇÃO</th>
        <th>MO</th>
        <th>STATUS</th>
      </tr>
    </thead>
  );
};

export default TableHeader;