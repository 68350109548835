import FormNewAdm from '../FormNewAdm';
import './OverlayNewAdm.css';

import { IoClose } from "react-icons/io5";

const OverlayNewAdm = ({ state, changeState, formValues, changeValues, requestStatus }) => {

    return( 
        <div className={ state ? 'overlayNewAdm show' : 'overlayNewAdm hide' } >
            <div className='squareNewAdm' >

                <div className='headerNewAdm' >
                    <IoClose onClick={ () => { changeState( false ) } } />
                </div>

                <FormNewAdm formValues={ formValues } changeValues={ changeValues } requestStatus={ requestStatus } />

            </div>
        </div>
    )

}

export default OverlayNewAdm;