import React from "react";
import ImagemLogin from "../Componentes/ImagemLogin";
import LoginContent from "../Componentes/LoginContent";

const Login = () => {

    return(
        <div className="contentContainer" >
            <LoginContent />
            <ImagemLogin />
        </div>
    )

}

export default Login;