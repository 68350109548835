import './NavBarFolgas.css'
import { LuPlus } from "react-icons/lu";

const NavBarFolgas = ({ stateOverlay, changeStateOverlay, changeSaveState, changeFormFunc }) => {

    return(
        <div className='nav_bar' >

            <img src="/images/Logo.png" id="logo_palmont" alt="logo_palmont" />

            <LuPlus size={33} className='newKM' onClick={ () => {

                changeStateOverlay( !stateOverlay )
                changeSaveState( 'new' )
                changeFormFunc( 'create' )

            } } />

        </div>
    )

}

export default NavBarFolgas;