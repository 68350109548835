import style from './FilterChild.module.css';

const FilterChild = ({ children }) => {

    return(
        <div className={ style.filterChild } >
            { children }
        </div>
    )

}

export default FilterChild;