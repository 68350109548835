import './PagamentosBody.css'

import { useState, useEffect } from 'react';
import { formatarData } from '../../Funcoes/FormatarData';
import { formatValorMoney } from '../../Funcoes/FormatValorMoney';
import PaymentSummary from '../PaymentSummary';

const PagamentosBody = ({ onFilter, dados }) => {

    const [ obraFilter, setObraFilter ] = useState('')
    const [ motivoFilter, setMotivoFilter ] = useState('')
    const [ startDate, setStartDate ] = useState('')
    const [ endDate, setEndDate ] = useState('')

    useEffect(() => {

        onFilter( startDate, endDate, obraFilter, motivoFilter )

    }, [ onFilter, startDate, endDate, obraFilter, motivoFilter ])

    return(
        <div className='pagamentosBody' >

            <div className='pagHeaderCont' >

                <div className='filterPagamentos' >

                    <div className='row-filter-1' >

                        <div className='filter_container' >
                            <select value={ obraFilter } onChange={ e => setObraFilter( e.target.value ) } >

                                <option value={''} >CENTRO CUSTO...</option>

                                { [ ...new Set( dados.map( obj => obj.centro_custo ) ) ].sort().map( obra => (
                                    <option value={ obra } key={ obra } >{ obra }</option>
                                ) ) }

                            </select>
                        </div>

                        <div className='filter_container' >
                            <select value={ motivoFilter } onChange={ e => setMotivoFilter( e.target.value ) } >

                                <option value={''} >MOTIVO...</option>

                                { [ ...new Set( dados.map( obj => obj.motivo ) ) ].sort().map( motivo => (
                                    <option value={ motivo } key={ motivo } >{ motivo }</option>
                                ) ) }

                            </select>
                        </div>

                        <div className='filter_container' >
                            <select>
                                <option>SELECIONE...</option>
                            </select>
                        </div>

                    </div>

                    <div className='row-filter-2' >

                        <div className='filter_container date_filter' >

                            <input
                                type='date'
                                value={ startDate }
                                onChange={ e => setStartDate( e.target.value ) }
                            />

                        </div>

                        <div className='filter_container date_filter' >

                            <input
                                type='date' 
                                value={ endDate }
                                onChange={ e => setEndDate( e.target.value ) }
                            />

                        </div>

                    </div>

                </div>

                <div className='valuesContainer' >

                    < PaymentSummary folhaValues={ dados } />

                </div>

            </div>

            <div className='squarePagamentos' >

                        <table>
                            <thead>
                                <tr>
                                    <th>VALOR</th>
                                    <th>MOTIVO</th>
                                    <th>MATRICULA</th>
                                    <th>COLABORADOR</th>
                                    <th>DATA</th>
                                    <th>CENTRO CUSTO</th>
                                </tr>
                            </thead>
                            <tbody>
                                { dados.map( obj => (
                                    <tr>
                                        <td>R$ { formatValorMoney( obj.valor ) }</td>
                                        <td>{ obj.motivo } </td>
                                        <td>{ obj.matricula }</td>
                                        <td>{ obj.colaborador }</td>
                                        <td>{ formatarData( obj.data ) }</td>
                                        <td>{ obj.centro_custo }</td>
                                    </tr>
                                ) ) }
                            </tbody>
                        </table> 

            </div>

        </div>
    )

}

export default PagamentosBody;