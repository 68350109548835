import './OverlayNewFolga.css'

import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { formatValorMoney } from '../../Funcoes/FormatValorMoney'
import { IoIosCloseCircleOutline } from "react-icons/io";

const OverlayNewFolga = ({ squareState, changeSquareState, user }) => {

    console.log( user )

    let { matricula } = useParams();

    const [ listaValores, setListaValores ] = useState([])
    const [ dataFolga, setDataFolga ] = useState('')
    const [ vendaFolga, setVendaFolga ] = useState('nao')

    const [ obra, setObra ] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/GetFolgaValues/", {
                    matricula: matricula
                });

                console.log(data[0]);
                setListaValores(data[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const get_obra = async () => {

            try{

                const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/GetColabObra/", {
                    matricula : matricula
                })

                console.log( data )

                setObra( data.OBRA );

            }catch( error ){

                console.log( error )

            }

        }

        fetchData();
        get_obra();

    }, [matricula]);


    const handleClick = ( event ) => {

        event.stopPropagation();

    }

    const handleFolga = ( event ) => {

        setDataFolga( event.target.value )

    }

    const handleVendaFolga = ( event ) => {

        setVendaFolga( event.target.value )

    }

    if( listaValores.PREENCHIDO === "SUCCESS" ){

        return(
            <div onClick={ () => { changeSquareState( !squareState ) } } className={ squareState ? 'overlayFolga-active' : 'overlayFolga-disabled' } >
    
                <div className='squareFolga' onClick={ handleClick } >
    
                    <form id='formNewFolga' >
    
                        <div className='formField' >
                            <p>VALOR DA PASSAGEM</p>
                            <input value={ "R$ " + listaValores.VALOR_PASSAGEM } disabled />
                        </div>
    
                        <div className='formField' >
                            <p>VALOR DA ALIMENTAÇÃO</p>
                            <input value={ "R$ " + formatValorMoney( listaValores.VALOR_ALIMENTACAO )} disabled />
                        </div>
    
                        <div className='formField' >
                            <p>VALOR DOS DIAS</p>
                            <input value={ "R$ " + formatValorMoney( listaValores.VALOR_DIAS )} disabled />
                        </div>
    
                        <div className='formField' >
                            <p>VALOR DA FOLGA</p>
                            <input value={ "R$ " + listaValores.VALOR_FOLGA_PASSAGEM } disabled />
                        </div>
    
                        <div className='formField' >
                            <p>VALOR DA VENDA</p>
                            <input value={ "R$ " + listaValores.VALOR_VENDA } disabled />
                        </div>
    
                        <div className='formField' >
                            <p>DATA DA FOLGA</p>
                            <input type='date' value={ dataFolga } onChange={ handleFolga } />
                        </div>

                        <div className='formField' >
                            <p>VENDA DA FOLGA</p>
                            <select value={ vendaFolga } onChange={ handleVendaFolga } >
                                <option value={"nao"} >NÃO</option>
                                <option value={"sim"} >SIM</option>
                            </select>
                        </div>
    
                        <button id='btnSave' onClick={ async ( event ) => {
                            
                            event.preventDefault();

                            const Now = new Date();

                            let ano = Now.getFullYear();
                            let mes = Now.getMonth() + 1;
                            let dia = Now.getDate();
                            let hora = Now.getHours();
                            let minutos = Now.getMinutes();
                            let segundos = Now.getSeconds();
                                                    
                            function padToTwoDigits(number) {
                                return number < 10 ? "0" + number : number;
                            }
                            
                            mes = padToTwoDigits(mes);
                            dia = padToTwoDigits(dia);
                            hora = padToTwoDigits(hora);
                            minutos = padToTwoDigits(minutos);
                            segundos = padToTwoDigits(segundos);
                            
                            const data_preenchida = `${ano}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
                            
                            try{

                                console.log( obra )

                                const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/SaveFolgaCampo/", {

                                    valor_pass : listaValores.VALOR_PASSAGEM,
                                    valor_aliment : listaValores.VALOR_ALIMENTACAO,
                                    valor_dias : listaValores.VALOR_DIAS,
                                    valor_folga : listaValores.VALOR_FOLGA_PASSAGEM,
                                    valor_venda : listaValores.VALOR_VENDA,
                                    dias_gozo : listaValores.DIAS_GOZO,
                                    data_folga : dataFolga,
                                    prazo : listaValores.PRAZO,
                                    matricula : matricula,
                                    preenchido : user,
                                    venda_folga : vendaFolga,
                                    obra : obra,
                                    data : data_preenchida

                                })

                                if( data.STATUS === "SUCCESS" ){

                                    window.location.reload();

                                }else{

                                    console.log( data )

                                }

                            }catch( error ){

                                console.log( error )

                            }

                        } } > SALVAR </button>
    
                    </form>
    
                </div>
    
            </div>
        )

    }else{

        return(

            <div onClick={ () => { changeSquareState( !squareState ) } } className={ squareState ? 'overlayFolga-active' : 'overlayFolga-disabled' } >

                <div className='squareFolga' onClick={ handleClick } >

                    <IoIosCloseCircleOutline id='icon_no_data' />
                    <p id='p_no_data' > DADOS NÃO PREENCHIDOS </p>
                    <p id='p_preecha_data' > FAVOR ADICIONAR DADOS NO MENU PRINCIPAL </p>

                </div>

            </div>

        )

    }

}

export default OverlayNewFolga;