import style from './StatsContainer.module.css';

const StatsContainer = ({ dados }) => {

    const examesVencidos = dados.filter( obj => obj.status === "atrasado" );
    const examesAVencer = dados.filter( obj => obj.status === "vencendo" );

    const totalValor = dados.reduce((acumulador, obj) => acumulador + parseFloat( obj.valor ) , 0);

    console.log( dados )

    return( 
        <div className={ style.statsContainer } >
            <p> TOTAL: { dados.length } </p>
            <p> VENCIDOS: { examesVencidos.length } </p>
            <p> A VENCER: { examesAVencer.length } </p>
            <p> VALOR: R$ { totalValor.toFixed(2).replace( '.', ',' ) } </p>
        </div>
    );

}

export default StatsContainer;