import React from "react";
import NavBarReps from "../Componentes/NavBarReps";
import '../Componentes/ContentReps/ContentReps.css'
import ContentReps from "../Componentes/ContentReps";

import { useState } from "react";
import PreencherRep from "../Componentes/PreencherRep";
import RepList from "../Componentes/RepList";

const Republicas = () => {

    const [ overlay, setOverlay ] = useState( false );
    const [ identRep, setIdentRep ] = useState( null );

    const [ overlayList, setOverlayList ] = useState( false );

    return(
        <div className="container" >

            <NavBarReps
                state={ overlay }
                changeState={ setOverlay }
            />

            <ContentReps
                changeIdentifier={ setIdentRep }
                changeShowState={ setOverlayList }
            />

            <PreencherRep
                state={ overlay }
                changeState={ setOverlay }
            />

            <RepList
                identifier={ identRep }
                showState={ overlayList }
                changeShowState={ setOverlayList }
            />

        </div>

    )

}

export default Republicas;