import AdmissaoFilter from '../AdmissaoFilter';
import AdmissaoSquare from '../AdmissaoSquare';
import './AdmissaoBody.css'

const AdmissaoBody = ({ tabelaValues, filterTable, loadAdmission, formValues, setFormValues, changeState, user, setRequestStatus }) => {

    return(

        <div className='admissaoBody' >

            <AdmissaoFilter
                tabelaValues={ tabelaValues }
                filterTable={ filterTable }
            />

            <AdmissaoSquare 
                tabelaValues={ tabelaValues } 
                loadAdmission={ loadAdmission } 
                formValues={ formValues } 
                setFormValues={ setFormValues } 
                changeState={ changeState }
                user={ user }
                setRequestStatus={ setRequestStatus }
            />

        </div>

    )

}

export default AdmissaoBody;