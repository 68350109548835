import './OverlayEditUser.css'

import { useState } from 'react';

import axios from "axios";


const OverlayEditUser = ({ overlayEdit, changeOverlayEdit, usuario, userAccess, getUserAccess }) => {

    const [ btnValue, setBtnValue ] = useState('SALVAR');
    const [ disabled, setDisabled ] = useState( false );

    const save_edit = async ( e ) => {

        e.preventDefault();

        setBtnValue('CARREGANDO...');
        setDisabled( true );

        try {
            
            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/EditPermission/', {
                user : usuario,
                valor : userAccess
            })

            if( data.STATUS === "SUCCESS" ){

                window.location.reload();   

            }

        } catch (error) {

            console.log( error );
            
        }finally{

            setBtnValue('SALVAR');
            setDisabled( false );

        }

    }

    return(
        <div className={ overlayEdit ? 'overlayEditUser show' : 'overlayEditUser hide' } onClick={ () => { 
            changeOverlayEdit( false );
        } } >

            <div className='editContainer' onClick={ ( e ) => { e.stopPropagation(); } } >

                <p>ALTERAR NÍVEL DE ACESSO DO USUARIO</p>

                <form>

                    <select value={ userAccess ? userAccess : '' } onChange={ ( e ) => {
                        
                        let { value } = e.target

                        getUserAccess( value );

                    } } >
                        <option value={ 0 } >0</option>
                        <option value={ 1 } >1</option>
                    </select>

                    <button onClick={ save_edit } disabled={ disabled } >{ btnValue }</button>

                </form>

            </div>

        </div>
    )

}

export default OverlayEditUser;