import './PreencherRep.css'

import { useState, useEffect } from 'react'
import { formatCEP } from '../../Funcoes/FormatCEP'
import axios from 'axios'
import { formatCaixaAlta } from '../../Funcoes/FormatCaixaAlta'
import { VscChromeClose } from "react-icons/vsc";
import InpuObrasAtivas from '../InputObrasAtivas'
import { useNavigate } from 'react-router-dom'

const PreencherRep = ({ state, changeState }) => {

    const [ logradouro, setLogradouro ] = useState( '' )
    const [ numero, setNumero ] = useState( '' )
    const [ complemento, setComplemento ] = useState( '' )
    const [ bairro, setBairro ] = useState( '' )

    const [ CEP, setCEP ] = useState( '' )
    const [ formatedCEP, setFormatedCEP ] = useState( '' )

    const [ cidade, setCidade ] = useState( '' )
    const [ uf, setUf ] = useState( '' )
    const [ obra, setObra ] = useState( '' )
    const [ apelido, setApelido ] = useState( '' )
    const [ vagas, setVagas ] = useState( 0 )

    const handleCEP = async ( event ) => {

        setFormatedCEP( formatCEP( event.target.value )  )
        setCEP( event.target.value )

    }

    const handleLog = ( event ) => {

        setLogradouro( formatCaixaAlta( event.target.value ) )

    }

    const handleNum = ( event ) => {

        setNumero( formatCaixaAlta( event.target.value ) )

    }

    const handleComp = ( event ) => {

        setComplemento( formatCaixaAlta( event.target.value ) )

    }

    const handleBairro = ( event ) => {

        setBairro( formatCaixaAlta( event.target.value ) )

    }

    const handleApel = ( event ) => {

        setApelido( formatCaixaAlta( event.target.value ) )

    }

    const handleObras = ( event ) => {

        setObra( event.target.value )

    }

    const handleVagas = ( event ) => {

        if( event.target.value === '' ){

            setVagas( 0 )

        }else{

            setVagas( event.target.value )

        }

    }

    const getAPIinfo = async () => {

        if( CEP.length === 9 ){

            const { data } = await axios.get(`https://viacep.com.br/ws/${ CEP }/json/`)

            setCidade( data.localidade.toUpperCase() )
            setUf( data.uf.toUpperCase() )

        }else{

            setCidade('')
            setUf('')

        }

    }

    const navigate = useNavigate();

    useEffect(() => {

        getAPIinfo();

    }, [ CEP ])

    return(
        <div className={ state ? 'overlay show' : 'overlay hide' } onClick={ () => { changeState( !state ) } } >
            <div className='tabelaRep' onClick={ ( e ) => { e.stopPropagation() } } >

                <div id='headerForm' >
                    <VscChromeClose id='closeIcon' onClick={ () => { changeState( !state ) } } />
                </div>

                <div className='FormField' >
                    <p> LOGRADOURO<span>*</span> </p>
                    <input type='text' placeholder='Ex: Avenida, Rua, Travessa, Praça' value={ logradouro } onInput={ handleLog } required={ true } />
                </div>

                <div className='FormField' >
                    <p> NÚMERO<span>*</span> </p>
                    <input type='text' placeholder='Ex: 1234' required onInput={ handleNum } value={ numero } />
                </div>

                <div className='FormField' >
                    <p> COMPLEMENTO </p>
                    <input type='text' placeholder='Ex: Portão Verde' onInput={ handleComp } value={ complemento } />
                </div>

                <div className='FormField' >
                    <p> BAIRRO<span>*</span> </p>
                    <input placeholder='Digite o bairro' required onInput={ handleBairro } value={ bairro } />
                </div>

                <div className='FormField' >
                    <p> CEP<span>*</span> </p>
                    <input placeholder='Ex: 12345-678' onInput={ handleCEP } value={ formatedCEP } required />
                </div>

                <div className='FormField' >
                    <p> CIDADE<span>*</span> </p>
                    <input type='text' placeholder='Cidade' readOnly value={ cidade } required />
                </div>

                <div className='FormField' >
                    <p> ESTADO<span>*</span> </p>
                    <input type='text' placeholder='Estado' readOnly value={ uf } required />
                </div>

                <div className='FormField' >
                    <p> CENTRO DE CUSTO<span>*</span> </p>
                    <InpuObrasAtivas obraState={ obra } setObraState={ handleObras } />
                </div>

                <div className='FormField' >
                    <p> APELIDO </p>
                    <input type='text' placeholder='Casa 2' value={ apelido } onInput={ handleApel } />
                </div>

                <div className='FormField' >
                    <p> VAGAS<span>*</span> </p>
                    <input type='number' value={ vagas } onChange={ handleVagas } />
                </div>

                <button type='submit' id='SaveRepBtn' onClick={ async ( e ) => {

                    e.preventDefault();

                    if( logradouro && numero && bairro && CEP && cidade && uf && vagas ){

                        try{

                            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/CreateNewRep/', {

                                logradouro : logradouro,
                                numero : numero,
                                complemento : complemento,
                                bairro : bairro,
                                cidade : cidade,
                                estado : uf,
                                centro_custo : obra,
                                apelido_rep : apelido,
                                vagas : vagas

                            })

                            console.log( data )

                            if( data.STATUS === 'SUCCESS' ){

                                navigate('/');

                            }else{

                                console.log('ERRO')

                            }

                        }catch( error ){

                            console.log( error )

                        }

                    }else{

                        console.log('Formulario não preenchido')

                    }

                    
                }} > SALVAR </button>

            </div>
        </div>
    )

}

export default PreencherRep;