import React from "react";
import NavBar from "../Componentes/NavBar";
import Content from "../Componentes/Content";
import LoadingScreen from "../Componentes/LoadingScreen";

import Cookies from 'js-cookie';
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Home = () => {

    const [ overlay, setOverlay ] = useState( false )

    const [ loadingScreen, setLoadingScreen ] = useState( true )
    const [ authenticated, setAuthenticated ] = useState( null )
    const [ user, setUser ] = useState('')
    const [ accessLvl, setAccessLvl ] = useState()

    useEffect(() => {
        document.title = "ADM | Home";
    }, []);

    const token = Cookies.get('token')

    useEffect( () => {

        const authUser = async () => {

            if( token ){

                try{

                    const { data } = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/API/AuthToken/", {

                        headers: {
                            Authorization: `Bearer ${token}`
                        }

                    });

                    if( data.STATUS === "SUCCESS" ){

                        setAuthenticated( true )
                        setUser( data.USER )
                        setAccessLvl( data.ACCESS_LVL )

                    }else{

                        setAuthenticated( false )

                    }

                    setLoadingScreen( false );

                }catch( error ){

                    console.log( error )

                }

            }else{

                setAuthenticated( false );
                setLoadingScreen( false );

            }

        }

        authUser();

    }, [])

    if( loadingScreen ){

        return(
            <LoadingScreen />
        )

    }else{

        return authenticated ? (
            <div className="content">
                <NavBar state={ overlay } changeState={ setOverlay } title='HOME' />
                <Content state={ overlay } changeState={ setOverlay } accessLvl={ accessLvl } />
            </div>
        ) : (
            <Navigate to="/Login" replace />
        )

    }

};

export default Home;