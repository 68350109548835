import style from './SideMenu.module.css';

const SideMenu = ({ children }) => {

    return(
        <div className={ style.sideMenu } onClick={ ( e ) => { e.stopPropagation() } } >
            { children }
        </div>
    )

}

export default SideMenu;