import style from './CheckBoxSquare.module.css';

const CheckBoxSquare = ({ label, checked, disabled, onChange }) => {

    return(
        <div className={ style.checklistSquare } >

            <label>
                <input type='checkbox' checked={ checked } disabled={ disabled } onChange={ onChange } />
                { label }
            </label>

        </div>
    )

}

export default CheckBoxSquare;