import './NewAdmField.css';

const NewAdmField = ({ type, inputType, placeholder, options, title, funcao, value, loadBtn }) => {

    const renderComponent = () => {

        switch( type ){

            case 'input' : 
                return inputType === 'submit' ? ( 
                    <input type='submit' value={ loadBtn ? 'CARREGANDO' : 'SALVAR' } className='submitBtn' onClick={ funcao } />
                    ) : (
                    <input type={ inputType } placeholder={ placeholder } onInput={ funcao } value={ value } />
                )
            case 'select' :
                return(
                    <select onChange={ funcao } value={ value } >
                        <option value={''} >SELECIONE...</option>
                        { options.map( opt => (
                            <option value={ opt } >{ opt }</option>
                        ))}
                    </select>
                )
            case 'textarea' :
                return(
                    <textarea onInput={ funcao } value={ value } />
                )
            default: return( null );

        }

    };

    return(
        <div className='newAdmField' >

            <p>{ title }</p>

            <div className='testeDiv' >
                { renderComponent() }
            </div>

        </div>
    )

}

export default NewAdmField;