export function formatTelefone(value) {

    value = value.replace(/\D/g, '');
  
    value = value.replace(/^(\d{2})(\d)/, '($1) $2');
  
    if (value.length <= 13) {
      value = value.replace(/(\d{4})(\d)/, '$1-$2'); // Para telefones fixos com 8 dígitos
    } else {
      value = value.replace(/(\d{5})(\d)/, '$1-$2'); // Para celulares com 9 dígitos
    }
  
    return value;

}