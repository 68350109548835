import React from "react"
import NavBarFolgas from "../Componentes/NavBarFolgas";
import BodyFolga from "../Componentes/BodyFolga";
import OverlayNewDistance from "../Componentes/OverlayNewDistance";

import { useState } from "react";

const FolgaInfo = () => {

    const [ overlay, setOverlay ] = useState( false )
    const [ formState, setFormState ] = useState('')

    const [ formFunc, setFormFunc ] = useState('')
    const [ editID, setEditID ] = useState('')

    const [ formValues, setFormValues ] = useState({
        obra : null,
        estado : null,
        municipio : null,
        distancia : 0,
        diasPrazo : 0,
        gozoFolga : 0,
        valorPass : '0.00',
        valorAlim : '0.00'
    })

    const [ saveState, setSaveState ] = useState('')

    return(
        <div className="contentFolga" >

            <NavBarFolgas
                stateOverlay={ overlay }
                changeStateOverlay={ setOverlay }
                stateForm={ formState }
                changeStateForm={ setFormState }
                changeSaveState={ setSaveState }
                changeFormFunc={ setFormFunc }
            />

            <BodyFolga
                stateForm={ formValues }
                changeFormState={ setFormValues }
                stateOverlay={ overlay }
                changeStateOverlay={ setOverlay }
                changeFormFunc={ setFormFunc }
                setEditID={ setEditID }
            />

            <OverlayNewDistance
                stateOverlay={ overlay }
                changeStateOverlay={ setOverlay }
                stateFormulario={ saveState }
                formFunc={ formFunc }
                editID={ editID }

                stateValues={ formValues }
                changeStateValues={ setFormValues }
            />

        </div>
    )

}

export default FolgaInfo;