import './ColabInfo.css'

import { formatDate } from '../../Funcoes/FormatData'
import { formatCPF } from '../../Funcoes/FormatCPF'

const ColabInfo = ({ data }) => {

    return(

        data.map( item => (

            <div className='colabInfo' >

                <p id='dadosTitle' > DADOS DO COLABORADOR </p>

                <div className='child' >
                    <p>MATRICULA: </p>
                    <input type='text' readOnly value={ item.MATRICULA } />
                </div>
                <div className='child' >
                    <p>NOME: </p>
                    <input type='text' readOnly value={ item.NOME } />
                </div>

                <div className='child' >
                    <p>CPF: </p>
                    <input type='text' readOnly value={ formatCPF( item.CPF ) } />
                </div>
                <div className='child' >
                    <p>CENTRO DE CUSTO: </p>
                    <input type='text' readOnly value={ item.OBRA } />
                </div>
                <div className='child' >
                    <p>FUNÇÃO: </p>
                    <input type='text' readOnly value={ item.FUNCAO } />
                </div>
                <div className='child' >
                    <p>DATA ADMISSAO: </p>
                    <input type='text' readOnly value={ formatDate( item.DATA_ADMISSAO ) } />
                </div>
                <div className='child' >
                    <p>DATA DEMISSAO: </p>
                    <input type='text' readOnly value={ ( item.DATA_DEMISSAO === '        ' ) ? "" : formatDate( item.DATA_DEMISSAO ) } />
                </div>
                <div className='child' >
                    <p>MÃO DE OBRA: </p>
                    <input type='text' readOnly value={ ( item.MAOOBRA === "I" ) ? "INDIRETA" : "DIRETA" } />
                </div><div className='child' >
                    <p>SEXO: </p>
                    <input type='text' readOnly value={ ( item.SEXO === "M" ) ? "MASCULINO" : "FEMININO" } />
                </div>
                <div className='child' >
                    <p>DATA DE NASCIMENTO: </p>
                    <input type='text' readOnly value={ formatDate( item.DATA_NASC ) } />
                </div>
                <div className='child' >
                    <p>SITUAÇÃO: </p>
                    <input type='text' readOnly value={ item.STATUS } />
                </div>
                <div className='child' >
                    <p>RG: </p>
                    <input type='text' readOnly value={ item.RG } />
                </div>
                <div className='child' >
                    <p>UF DO RG: </p>
                    <input type='text' readOnly value={ item.UF_RG } />
                </div>
                <div className='child' >
                    <p>MUNICIPIO DE RESIDÊNCIA: </p>
                    <input type='text' readOnly value={ item.MUNICIPIO } />
                </div>
                <div className='child' >
                    <p>PIS: </p>
                    <input type='text' readOnly value={ item.PIS } />
                </div>
                <div className='child' >
                    <p>CONTATO: </p>
                    <input type='text' readOnly value={ '(' + item.DDDTELEF + ') ' + item.TELEFONE } />
                </div>

            </div>

        ) )

    )

}

export default ColabInfo