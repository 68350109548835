import './FolhaPagHeader.css';

import { useEffect, useState } from 'react';
import { formatarValor } from '../../Funcoes/FormatarValor';

const FolhaPagHeader = ({ dados, filter }) => {

    const [ filterMat, setFilterMat ] = useState('');
    const [ filterObra, setFilterObra ] = useState('');
    const [ filterMotivo, setFilterMotivo ] = useState('');
    const [ filterDataInicio, setFilterDataInicio ] = useState('');
    const [ filterDataFinal, setFilterDataFinal ] = useState('');

    useEffect(() => {

        filter( filterMat, filterObra, filterMotivo, filterDataInicio, filterDataFinal );
        
    }, [ filterMat, filterObra, filterMotivo, filterDataInicio, filterDataFinal, filter ]);

    const [ totalValue, setTotalValue ] = useState( '0.00 ')

    useEffect(() => {

        let total = dados.reduce((acc, obj) => acc + Number(obj.valor) , 0);
        total = total.toFixed( 2 );

        setTotalValue(total);

    }, [dados]);

    return(
        <div className='folhaPagHeader' >

            <div className='filtersFolhaPag' >

                <div className='filter-row-1' >

                    <input className='filterMatricula' type='text' maxLength={ 6 } placeholder='MATRICULA...' value={ filterMat } onInput={ ( e ) => {

                        let { value } = e.target;
                        value = value.replace(/\D/g, '');

                        setFilterMat( value );

                    } } />

                    <select value={ filterObra } onChange={ ( e ) => {

                        let { value } = e.target;

                        setFilterObra( value );

                    }} >
                        <option value={''} >CENTRO CUSTO...</option>

                        { [ ...new Set( dados.map( obj => obj.centro_custo ) ) ].sort().map( obra => (
                            <option value={ obra } key={ obra } >{ obra }</option>
                        ) ) }

                    </select>

                    <select value={ filterMotivo } onChange={ ( e ) => {

                        let { value } = e.target;

                        setFilterMotivo( value );

                    }} >

                        <option value={''} >MOTIVO...</option>
                        { [ ...new Set( dados.map( obj => obj.motivo ) ) ].sort().map( motivo => (
                            <option value={ motivo } key={ motivo } >{ motivo }</option>
                        ) ) }

                    </select>

                </div>

                <div className='filter-row-2' >

                    <input type='date' value={ filterDataInicio } onInput={ ( e ) => {
                        let { value } = e.target;

                        setFilterDataInicio( value );
                    }} />

                    <input type='date' value={ filterDataFinal } onInput={ ( e ) => {
                        let { value } = e.target;

                        setFilterDataFinal( value );
                    }} />
                    
                </div>

            </div>

            <div className='statsFolhaPag' >
                <div className='summaryPag' >
                    <p>R$ { formatarValor( totalValue ) }</p>
                </div>
            </div>

        </div>
    )

}

export default FolhaPagHeader;