import AdmissaoBtnSidebar from '../AdmissaoBtnSidebar';
import './SideBarAdmissao.css';

import { IoMdPersonAdd } from "react-icons/io";

const SideBarAdmissao = ({ user, overlayState, setOverlayState, changeOverlayNew, setFormValues, setRequestStatus }) => {

    return( 

        <div className={ overlayState ? 'overlay_sidebar_adm show' : 'overlay_sidebar_adm hide' } onClick={ () => { setOverlayState( false ) } } >

            <div className='sidebar_adm' onClick={ ( e ) => { e.stopPropagation() } } >

                <AdmissaoBtnSidebar name={ 'NOVO COLABORADOR' } icon={ <IoMdPersonAdd /> } click={ () => {

                    setRequestStatus( 'create' )
                    setFormValues({
                        'nome' : '',
                        'cpf' : '',
                        'funcao' : '',
                        'desistencia' : '',
                        'prev_chegada' : '',
                        'obs' : '',
                        'telefone' : '',
                        'estado' : '',
                        'municip' : '',
                        'receb_curr' : '',
                        'data_contato' : '',
                        'data_chegada' : '',
                        'data_exame' : '',
                        'doc_admissao' : '',
                        'doc_sesmt' : '',
                        'admissao' : '',
                        'cadastro_portal' : '',
                        'integracao' : '',
                        'entrada_site' : '',
                        'num_uniforme' : '',
                        'num_calcado' : 0,
                        'user' : user.USER,
                        'preenchido' : '',
                        'centro_custo' : ''
                    })

                    setOverlayState( false )
                    changeOverlayNew( true )
                } } />

            </div>

        </div>

    )

}

export default SideBarAdmissao;