import style from './FilterInput.module.css';

const FilterInput = ({ type, placeholder, value, onChange }) => {

    return(
        <input type={ type } placeholder={ placeholder } value={ value } onChange={ onChange } />
    )

}

export default FilterInput;