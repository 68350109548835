import './OverlayAddBenefits.css'

import InputMoney from '../InputMoney';

import { IoClose } from "react-icons/io5";
import { useCallback, useEffect, useState } from 'react';
import { formatMoney } from '../../Funcoes/FormatMoney';
import axios from 'axios';

const OverlayAddBenefits = ({ benefitsAddState, setBenefitsAddState, formValues, setFormValues, user }) => {

    console.log( formValues );

    const [ readyToSave, setReady ] = useState( false );

    const beneficios = [
        'AUXILIO ALIMENTAÇÃO',
        'AUXILIO EDUCAÇÃO',
        'AUXILIO INTERNET',
        'AUXILIO MORADIA',
        'DIARIA DE VIAGEM',
        'VA OBRA / CARTÃO ALELO',
        'VALE TRANSPORTE'
    ]

    const handleChange = ( field ) => ( event ) => {

        let { value } = event.target

        value = field === 'valor' ? formatMoney( value ) : value;

        setFormValues( prevValues => ({

            ...prevValues,
            [ field ]: value

        }))

    }

    const update_time = () => {

        const Now = new Date();

        let ano = Now.getFullYear();
        let mes = Now.getMonth() + 1;
        let dia = Now.getDate();
        let hora = Now.getHours();
        let minutos = Now.getMinutes();
        let segundos = Now.getSeconds();
                                
        function padToTwoDigits(number) {
            return number < 10 ? "0" + number : number;
        }
        
        mes = padToTwoDigits(mes);
        dia = padToTwoDigits(dia);
        hora = padToTwoDigits(hora);
        minutos = padToTwoDigits(minutos);
        segundos = padToTwoDigits(segundos);
        
        const data_preenchida = `${ano}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

        setFormValues( prevValues => ({

            ...prevValues,
            'data' : data_preenchida,
            'user' : user

        }))

    }

    const save_benefit = ( e ) => {

        e.preventDefault();
        update_time();

        setReady( true );

    }

    const save_form = useCallback( async () => {

        console.log( formValues );

        let sql = formValues.func === 'create' ? 'https://sistemaintegrado.palmont.com.br/Painel/API/AddNewBenefit/' : 'https://sistemaintegrado.palmont.com.br/Painel/API/EditBenefit/'

        try{

            const { data } = await axios.post( sql , formValues );
            

            if( data.STATUS === 'SUCCESS' ){

                window.location.reload();

            }

        }catch( error ){

            console.log( error )

        }

    }, [ formValues ])

    useEffect(() => {

        if( readyToSave ){

            save_form()
            setReady( false )

        }

    }, [ readyToSave, save_form ])

    return(
        <div className={ benefitsAddState ? 'overlayAddBenefit show' : 'overlayAddBenefit hide' } >

            <div className='squareAddBenefit' >

                <div className='benefitClose' >
                    <IoClose onClick={ () => { setBenefitsAddState( false ) } } />
                </div>

                <form>

                    <div className='benefitField first_field' >

                        <p>BENEFÍCIO</p>

                        <select value={ formValues.beneficio } onChange={ handleChange('beneficio') } >
                            <option value={''} >SELECIONE...</option>

                            { beneficios.map( value => (

                                <option key={ value } value={ value } >{ value }</option>

                            ) ) }

                        </select>

                    </div>

                    <div className='benefitField' >

                        <p>VALOR</p>

                        <InputMoney valorState={ formValues.valor } changeValorState={ handleChange('valor') } />

                    </div>

                    <input type='submit' value={'SALVAR'} className='submitBenefit' onClick={ save_benefit } />

                </form>

            </div>

        </div>
    )

}

export default OverlayAddBenefits;