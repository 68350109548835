import axios from "axios"

export async function UseAuth( token ){

    try{

        const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/AuthToken/', {

            headers: {
                Authorization: `Bearer ${token}`
            }

        })

        console.log( data )

        return( data )

    }catch( error ){

        console.log( error );

    }

}