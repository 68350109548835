import "./ColabsStats.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectAlojamento from "../SelectAlojamento";
import FolgasCampo from "../FolgasCampo";
import InpuObrasAtivas from "../InputObrasAtivas";
import LiderByObra from "../LiderByObra";

import { format } from 'date-fns';



const ColabsStats = ({ data, user }) => {

    const [contratos, setContratos] = useState([]);
    const [lideres, setLideres] = useState([]);

    const [ cpf ] = useState( data[0].CPF )
    const [ matricula ] = useState( data[0].MATRICULA )
    const [ obra ] = useState( data[0].OBRA )

    const [ cracha, setCracha ] = useState( data[0].CRACHA )
    const [ contrato, setContrato ] = useState( data[0].CONTRATO )
    const [ disciplina, setDisciplina ] = useState( data[0].DISCIPLINA )
    const [ encarregado, setEncarregado ] = useState( data[0].ENCARREGADO )
    const [ supervisor, setSupervisor ] = useState( data[0].SUPERVISOR )
    const [ supGeral, setSupGeral ] = useState( data[0].SUP_GERAL )
    const [ alojamento, setAlojamento ] = useState( data[0].ALOJAMENTO )
    const [ checkin, setCheckin ] = useState( data[0].CHECKIN )
    const [ checkout, setCheckout ] = useState( data[0].CHECKOUT )
    const [ dataAso, setDataAso ] = useState( data[0].DATAASO )

    const [ obraLocado, setObraLocado ] = useState( data[0].LOCADO_TEMP )
    const [ alojTempState, setAlojTemp ] = useState( data[0].ALOJ_TEMP )
    const [ chefeTemp, setChefeTemp ] = useState( data[0].CHEFE_TEMP )

    const [disabledbtn, setDisabled] = useState(false);

    const changeCracha = ( event ) => {

        setCracha( event.target.value )

    }

    const changeContrato = ( event ) => {

        setContrato( event.target.value )

    }

    const changeDisciplina = ( event ) => {

        setDisciplina( event.target.value )

    }

    const changeEncarregado = ( event ) => {

        setEncarregado( event.target.value )

    }

    const changeSupervisor = ( event ) => {

        setSupervisor( event.target.value )

    }

    const changeSupGeral = ( event ) => {

        setSupGeral( event.target.value )

    }

    const changeAlojamento = ( event ) => {

        setAlojamento( event.target.value )

    }

    const handleCheckIn = ( event ) => {

        if( event.target.value === '' ){

            setCheckin( null )

        }else{

            setCheckin( event.target.value )

        }

    }

    const handleCheckOut = ( event ) => {
        
        if( event.target.value === '' ){

            setCheckout( null )

        }else{

            setCheckout( event.target.value )

        }

    }

    const handleDataAso = ( event ) => {

        if( event.target.value === '' ){

            setDataAso( null )

        }else{

            setDataAso( event.target.value )

        }

    }

    const handleObraLocado = ( event ) => {

        setObraLocado( event.target.value )

    }

    const handleAlojTemp = ( event ) => {

        setAlojTemp( event.target.value )

    }

    const handleChefeTemp = ( event ) => {

        setChefeTemp( event.target.value )

    }

    const loadingSave = async () => {
        console.log("Rodou");
    
        setDisabled(true);
    
        const currentDateTime = new Date();
        const formattedDateTime = format(currentDateTime, 'yyyy-MM-dd HH:mm');
    
        await toast.promise(
          axios.post("https://sistemaintegrado.palmont.com.br/Painel/assets/php/saveColabInfo.php", {
            cpf: cpf,
            matricula: matricula,
            cracha: cracha,
            contrato: contrato,
            disciplina: disciplina,
            encarregado: encarregado,
            supervisor: supervisor,
            supervisorGeral: supGeral,
            alojamento: alojamento,
            checkin: checkin,
            checkout: checkout,
            dataaso: dataAso,
            locado: obraLocado,
            aloj_temp: alojTempState,
            chefe_temp: chefeTemp,
            user: user,
            data_hora: formattedDateTime
          }),
          {
            pending: "Salvando informações...",
            success: "Salvo com sucesso!!!",
            error: "Erro ao salvar"
          }
        );
    
        setDisabled(false);
      };

    useEffect(() => {

        const getContratos = async () => {
            try {

                const response = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getContratos.php');
                setContratos(response.data);

            } catch (error) {

                console.error('Error fetching contracts:', error);

            }
        };

        const getLideres = async () => {
            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getLideres.php');
                setLideres( data )

            } catch (error){

                console.error(error)

            }
        }

        getContratos();
        getLideres();
    }, []);

    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    return (
        data.map(item => (
            <div key={item.id} className="infoContainer">

                <div className="colabStats">

                    <p id="infoTitle">PREENCHER INFORMAÇÕES</p>

                    <div className="field">

                        <p>DATA ASO:</p>
                        <input type="date" value={ dataAso } onChange={ handleDataAso } />

                    </div>



                    <div className="field">

                        <p>CRACHÁ DE ACESSO:</p>
                        <input type='text' value={ cracha } onInput={ changeCracha } />

                    </div>

                    <div className="field">

                        <p>CONTRATO:</p>
                        <select value={ contrato } onChange={ changeContrato } >
                            <option>CONTRATO...</option>
                            {contratos.filter(value => value.centro_custo === item.OBRA).map(contract => (
                                <option key={contract.id} value={contract.nome_contrato}>{contract.nome_contrato}</option>
                            ))}
                        </select>

                    </div>

                    <div className="field">

                        <p>DISCIPLINA:</p>
                        <select value={ disciplina } onChange={ changeDisciplina } >
                            <option>DISCIPLINA...</option>
                            <option value="ALMOXERIFADO/MATERIAIS">ALMOXERIFADO/MATERIAIS</option>
                            <option value="ANDAIMES">ANDAIMES</option>
                            <option value="APOIO OPERACIONAL">APOIO OPERACIONAL</option>
                            <option value="CALDEIRARIA">CALDEIRARIA</option>
                            <option value="CONSTRUÇÃO CIVIL">CONSTRUÇÃO CIVIL</option>
                            <option value="ELETRICA E INSTRUMENTAÇÃO">ELETRICA E INSTRUMENTAÇÃO</option>
                            <option value="IÇAMENTO E MOVIMENTAÇÃO DE CARGAS">IÇAMENTO E MOVIMENTAÇÃO DE CARGAS</option>
                            <option value="ISOLAMENTO TÉRMICO">ISOLAMENTO TÉRMICO</option>
                            <option value="LOGÍSTICA E OPERAÇÃO">LOGÍSTICA E OPERAÇÃO</option>
                            <option value="MECÂNICA DE MANUTENÇÃO">MECÂNICA DE MANUTENÇÃO</option>
                            <option value="MECÂNICA">MECÂNICA</option>
                            <option value="QUALIDADE">QUALIDADE</option>
                            <option value="RESTAURANTE">RESTAURANTE</option>
                            <option value="SERVIÇOS ADMINISTRATIVOS">SERVIÇOS ADMINISTRATIVOS</option>
                            <option value="SERVIÇOS DE PINTURA">SERVIÇOS DE PINTURA</option>
                            <option value="SERVIÇOS GERAIS">SERVIÇOS GERAIS</option>
                            <option value="SOLDAGEM">SOLDAGEM</option>
                            <option value="SSMA">SSMA</option>
                            <option value="TUBULAÇÃO">TUBULAÇÃO</option>
                        </select>

                    </div>

                    <div className="field">

                        <p>ENCARREGADO:</p>
                        <select value={ encarregado } onChange={ changeEncarregado } >
                            <option>ENCARREGADO...</option>
                            { lideres.map( lider => ( 
                                <option key={ lider.RA_CIC } value={ lider.RA_NOMECMP } >{ lider.RA_NOMECMP }</option>
                            ))}
                        </select>

                    </div>

                    <div className="field">

                        <p>SUPERVISOR:</p>
                        <select value={ supervisor } onChange={ changeSupervisor } >
                            <option>SUPERVISOR...</option>
                            { lideres.map( lider => ( 
                                <option key={ lider.RA_CIC } value={ lider.RA_NOMECMP } >{ lider.RA_NOMECMP }</option>
                            ))}
                        </select>

                    </div>

                    <div className="field">

                        <p>SUPERVISOR GERAL:</p>
                        <select value={ supGeral } onChange={ changeSupGeral } >
                            <option>SUPERVISOR GERAL...</option>
                            { lideres.map( lider => ( 
                                <option key={ lider.RA_CIC } value={ lider.RA_NOMECMP } >{ lider.RA_NOMECMP }</option>
                            ))}
                        </select>

                    </div>

                    <div className="field">

                        <p>ALOJAMENTO:</p>
                        <SelectAlojamento aloj={ alojamento } changeAloj={ changeAlojamento } obra={ obra } />

                    </div>

                    <div className="field">

                        <p>CHECK-IN:</p>
                        <input type="date" value={ checkin } onChange={ handleCheckIn } />

                        <p id="checkout_p" >CHECK-OUT:</p>
                        <input type="date" value={ checkout } onChange={ handleCheckOut } />

                    </div>

                    <div className="field" >

                        <p>LOCADO TEMP.</p>
                        <InpuObrasAtivas obraState={ obraLocado } setObraState={ handleObraLocado } />

                        <p id="aloj_p" >ALOJAMENTO TEMP.</p>
                        <SelectAlojamento aloj={ alojTempState } changeAloj={ handleAlojTemp } obra={ obraLocado } />

                        <p id="chefe_p" >CHEFE TEMP.</p>
                        <LiderByObra obra={ obraLocado } liderState={ chefeTemp } changeLiderState={ handleChefeTemp } />

                    </div>

                    <button id="save_edit" onClick={ loadingSave } disabled={ disabledbtn } >SALVAR EDIÇÕES</button>

                </div>
                <ToastContainer
                    position="top-center"
                    theme="dark"
                ></ToastContainer>

                <FolgasCampo />

            </div>
        ))
    );
};

export default ColabsStats;