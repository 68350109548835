import './SideBarPagamentos.css';

import * as XLSX from 'xlsx';

import { useEffect, useState } from 'react';

import { MdOutlineAttachMoney } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { formatDateMMYYYY } from '../../Funcoes/FormatDateMMYYYY';

const SideBarPagamentos = ({ overlayState, changeOverlayState, changeOverlayPag, dados }) => {

    const [ folhaPagamento, setFolhaPagamento ] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState( false )

    console.log( dados );

    const tranformArray = ( array ) => {

        console.log( array )

        const result = array.reduce(( acc, curr ) => {

            const key = `${ curr.colaborador }-${ curr.matricula }`;

            if( !acc[key] ){

                acc[key] = {

                    "MATRICULA": curr.matricula,
                    "CC": curr.centro_custo,
                    "NOME": curr.colaborador,
                    "DESCONTO ALMOXARIFADO / DESCONTO DIVERSOS": 0.00,
                    "DESCONTO DE VALORES EXTRA": 0.00,
                    "DIARIA DE VIAGEM": 0.00,
                    "REEMBOLSO DIVERSOS / FOLGA DE DOMINGO": 0.00,
                    "REEMBOLSO DE PASSAGENS": 0.00,
                    "PLR": 0.00,
                    "VALE TRANSPORTE": 0.00,
                    "AUXILIO ALIMENTAÇÃO": 0.00,
                    "AUXILIO EDUCAÇÃO": 0.00,
                    "AUXILIO INTERNET": 0.00,
                    "AUXILIO MORADIA": 0.00,
                    "VA OBRA / CARTÃO ALELO": 0.00,
                    "FOLGA DE CAMPO": 0.00,
                    "V.A.CONV.COLETIVA": 0.00,
                    "ADC PF": 0.00

                }

            }

            const valor = parseFloat(curr.valor);

            if( curr.motivo === "DESCONTO ALMOXARIFADO" || curr.motivo === "DESCONTO DIVERSOS" ){

                acc[key]["DESCONTO ALMOXARIFADO / DESCONTO DIVERSOS"] += valor

            }else if( curr.motivo === "REEMBOLSO DIVERSOS" || curr.motivo === "FOLGA DE DOMINGO" ){

                acc[key]["REEMBOLSO DIVERSOS / FOLGA DE DOMINGO"] += valor

            }else{

                acc[key][curr.motivo] += valor

            }

            return acc;

        }, {});

        return Object.values( result );

    }

    useEffect(() => {

        let new_dados = tranformArray( dados );
        console.log( new_dados );

        setFolhaPagamento( new_dados );

    }, [ dados ])

    const download_excel = async () => {

        setLoadingExcel(true);
    
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([
            ['', '', '', '434', '499', '252', '288', '129', '245', '282', '250', '238', '254', '253', '0', '55', '243', '0'],
            ['MAT', 'CC', 'NOME', 'DESCONTO ALMOXARIFADO / DESCONTO DIVERSOS', 'DESCONTO DE VALORES EXTRA', 'DIARIA DE VIAGEM', 'REEMBOLSO DIVERSOS / FOLGA DE DOMINGO', 'REEMBOLSO DE PASSAGENS', 'PLR', 'VALE TRANSPORTE', 'AUXILIO ALIMENTAÇÃO', 'AUXILIO EDUCAÇÃO', 'AUXILIO INTERNET', 'AUXILIO MORADIA', 'VA OBRA / CARTÃO ALELO', 'FOLGA DE CAMPO', 'V.A.CONV.COLETIVA', 'ADC PF']
        ]);
        
        const dataSheet = XLSX.utils.json_to_sheet(folhaPagamento, { skipHeader: true });
        XLSX.utils.sheet_add_json(worksheet, folhaPagamento, { origin: 'A3', skipHeader: true });    
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        await XLSX.writeFile(workbook, "Folha_de_Pagamento.xlsx");
    
        setLoadingExcel(false);
    }

    return(

        <div className={ overlayState ? "OverlaySideBarPag show" : "OverlaySideBarPag hide" } onClick={ () => { changeOverlayState( false ) } } >
            <div className='SideBarPag' onClick={ ( event ) => { event.stopPropagation(); } } >

                <div className='btn-side-bar' onClick={ () => {
                        changeOverlayState( false )
                        changeOverlayPag( true )
                    } } >
                    <MdOutlineAttachMoney size={ 30 } className='pag-sidebar-icon' />
                    <p>NOVO LANÇAMENTO</p>
                </div>

                <div className='btn-side-bar' onClick={ download_excel } >

                    { loadingExcel ? (

                        <>
                            <AiOutlineLoading3Quarters />
                        </>

                    ) : (

                        <>
                            <SiMicrosoftexcel size={ 24 } className='pag-sidebar-icon' />
                            <p>GERAR RELATÓRIO EXCEL</p>
                        </>

                    ) }

                </div>

            </div>
        </div>

    )

}
export default SideBarPagamentos;