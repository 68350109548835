import style from './CheckboxesList.module.css';

const CheckboxesList = ({ children }) => {

    return(
        <div className={ style.checklistField } >

            { children }

        </div>
    )

}

export default CheckboxesList;