import axios from 'axios';
import './BodyFolga.css'

import { useState, useEffect } from 'react';
import { formatValorMoney } from '../../Funcoes/FormatValorMoney';

const BodyFolga = ({ stateForm, changeFormState, stateOverlay, changeStateOverlay, changeFormFunc, setEditID }) => {

    const [ lista, setLista ] = useState([])

    useEffect( () =>  {

        const getDistances = async () => {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetDistances/')

            console.log( data )

            setLista( data )

        } 

        getDistances();

    }, [])

    return(
        <div className='bodyFolga' >

            <div className='distanceSquare' >

                <table>
                    <thead>
                        <tr>
                            <th>CENTRO CUSTO</th>
                            <th>MUNICIPIO</th>
                            <th>ESTADO</th>
                            <th>DISTANCIA</th>
                            <th>DIAS PRAZO</th>
                            <th>DIAS GOZO</th>
                            <th>VALOR DA PASSAGEM</th>
                            <th>VALOR DA ALIMENTAÇÃO</th>
                        </tr>
                    </thead>
                    <tbody>
                        { lista.map( obj => (
                            <tr key={ obj.id } onClick={ () => {

                                changeFormState({

                                    obra: obj.obra,
                                    estado: obj.estado,
                                    municipio: obj.municipio,
                                    distancia: obj.distancia,
                                    diasPrazo: obj.dias_prazo,
                                    gozoFolga: obj.gozo_dias,
                                    valorPass: obj.valor_passagem,
                                    valorAlim: obj.valor_aliment

                                })

                                changeStateOverlay( !stateOverlay )
                                changeFormFunc( 'edit' )
                                setEditID( obj.id )

                            } } >
                                <td>{ obj.obra }</td>
                                <td>{ obj.municipio }</td>
                                <td>{ obj.estado }</td>
                                <td>{ obj.distancia } KM</td>
                                <td>{ obj.dias_prazo }</td>
                                <td>{ obj.gozo_dias }</td>
                                <td> R$ { formatValorMoney( obj.valor_passagem ) }</td>
                                <td> R$ { formatValorMoney( obj.valor_aliment ) }</td>
                            </tr>
                        ) ) }
                    </tbody>
                </table>

            </div>

        </div>
    )

}

export default BodyFolga;