import './RegisterContent.css'

import { useState } from 'react';

const RegisterContent = () => {

    const [ cpf, setCPF ] = useState('')
    const [ name, setName ] = useState('')

    return(
        <div className='registerContent' >
            <img className='logoPalmont' src='/Images/Logo.png' alt='palmontLogo' />
            <div className='containerRegister'>
                <form id='formulario' >
                    <div className='fieldForm' >
                        <input type='text' placeholder='CPF:' required />
                    </div>
                    <div className='fieldForm' >
                        <div className='nameInput' >
                            <input placeholder='Nome:' readOnly value={"MATHEUS"} />
                        </div>
                        <div className='surnameInput' >
                            <input placeholder='Sobrenome:' readOnly />
                        </div>
                    </div>
                    <div className='fieldForm' >
                        <input placeholder='E-mail Palmont:' />
                    </div>
                    <div className='fieldForm' >
                        <input placeholder='Senha:' />
                    </div>
                    <button> SALVAR </button>
                </form>
            </div>
        </div>
    )
}

export default RegisterContent;