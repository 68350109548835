import './InputObrasAtivas.css'

import axios from 'axios';
import { useState, useEffect } from 'react';

const InpuObrasAtivas = ({ obraState, setObraState }) => {

    const [ obras, setObras ] = useState([])

    useEffect( () => {

        const getObras = async () => {

            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/')
                setObras( data )

            }catch(error){

                console.log( error )

            }

        }

        getObras();

    }, []);



    return(

        <select className='obrasSelect' value={ obraState } onChange={ setObraState } >

            <option value={''} >SELECIONE...</option>

            { obras.map( obra => ( 

                <option key={obra.OBRA} value={obra.OBRA}>{obra.OBRA}</option>

            ) ) }

        </select>

    )

}

export default InpuObrasAtivas;