import axios from 'axios';
import './OverlayObrasLiberadas.css';

import { useState, useEffect } from 'react';
import { MdDelete } from "react-icons/md";

const OverlayObrasLiberadas = ({ usuario, stateOverlay, changeStateOverlay, setOverlayAdd }) => {

    const [ loading, setLoading ] = useState( true );
    const [ obrasLib, setObrasLib ] = useState([]);

    useEffect(() => {

        setLoading( true );

        const getObrasLib = async () => {

            try{

                const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasLib/", {
                    id : usuario
                })

                console.log( data );
                setObrasLib( data );

            }catch(error){

                console.log( error );

            }finally{

                setLoading( false );

            }

        }

        getObrasLib();

    }, [ usuario ]);

    const openAddCC = () => {

        changeStateOverlay( false )
        setOverlayAdd( true )

    }

    const deleteObra = async ( id ) => {

        try{

            const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/RemoveAccess/", {
                id : id
            })

            if( data.STATUS === "SUCCESS" ){

                window.location.reload()

            }

        }catch( error ){

            console.log( error );

        }

    }

    return(
        <div className={ stateOverlay ? 'overlayObrasLib show' : 'overlayObrasLib hide' } onClick={ () => {
            changeStateOverlay( false );
        } } >

            <div className='contObrasLib' onClick={ ( e ) => {
                e.stopPropagation();
            } } >

                <p>LISTA DE OBRAS LIBERADAS</p>
                <div className='squareObrasLib' >
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={ 2 } >OBRA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td>CARREGANDO...</td>
                                    </tr>
                                ) : (
                                    <>
                                        { obrasLib.length === 0 ? (
                                            <tr>
                                                <td>Nenhuma obra liberada para esse colaborador.</td>
                                            </tr>
                                        ) : (
                                            obrasLib.map(( obj ) => (
                                                <tr key={ obj.id }>
                                                    <td className='colunaObraName' >{ obj.centro_custo }</td>
                                                    <td>{ <MdDelete className='deleteObraBtn' onClick={ () => { deleteObra( obj.id ) } } /> }</td>
                                                </tr>
                                            ))
                                        )}
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <button onClick={ () => {

                    openAddCC();

                } } > LIBERAR ACESSO DE NOVO CENTRO DE CUSTO </button>
            </div>
        </div>
    )
}

export default OverlayObrasLiberadas;