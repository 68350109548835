import FolhaPagHeader from '../FolhaPagHeader';
import FolhaPagSquare from '../FolhaPagSquare';
import './FolhaPagBody.css';

const FolhaPagBody = ({ dados, filter, usuario, deleteTableRow }) => {

    return(
        <div className='folhaPagBody' >

            <FolhaPagHeader
                dados={ dados }
                filter={ filter }
            />

            <FolhaPagSquare
                dados={ dados }
                usuario={ usuario }
                deleteTableRow={ deleteTableRow }
            />

        </div>
    )

}

export default FolhaPagBody;