import React from "react";
import ImagemLogin from "../Componentes/ImagemLogin";
import LoginContent from "../Componentes/LoginContent";
import RegisterContent from "../Componentes/RegisterContent";

const Register = () => {

    return(
        <div className="contentContainer" >
            <RegisterContent />
            <ImagemLogin />
        </div>
    )

}

export default Register;