import './FolgasCampo.css'

import axios from 'axios';

import { useState, useEffect } from 'react';
import { formatValorMoney } from '../../Funcoes/FormatValorMoney';
import { formatarData } from '../../Funcoes/FormatarData';
import { useParams } from 'react-router-dom';

const FolgasCampo = () => {

    let { matricula } = useParams();

    const [ folgas, setFolgas ] = useState( [] )
    const [ laoding, setLoading ] = useState( true )

    useEffect( () => {

        const getData = async () => {

            const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/GetFolgas/", {
                matricula : matricula
            })

            setFolgas( data )
            setLoading( false )

        }

        getData();

    }, [])

    return(
        <div className='folgasContainer' >
            <p id='titleFolgas' > FOLGAS DE CAMPO </p>
            <div className='squareFolgas' >
                <table>
                    <thead>
                        <tr>
                            <th> VALOR DA PASSAGEM </th>
                            <th> VALOR DIAS </th>
                            <th> VALOR DA FOLGA </th>
                            <th> VALOR DA VENDA </th>
                            <th> DATA </th>
                            <th> DIAS GOZO </th>
                            <th> PRÓXIMA FOLGA </th>
                        </tr>
                    </thead>
                    { laoding ? (
                        <tbody>
                            <tr>
                                <td colSpan={ 7 } > CARREGANDO... </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            { folgas.map ( obj => (

                                <tr>
                                    <td>R$ { formatValorMoney( obj.valor_passagem ) }</td>
                                    <td>R$ { formatValorMoney( obj.valor_dias ) }</td>
                                    <td>R$ { formatValorMoney( obj.valor_folga ) }</td>
                                    <td>R$ { formatValorMoney( obj.valor_venda ) }</td>
                                    <td>{ formatarData(obj.data_folga) }</td>
                                    <td>{ obj.dias_gozo }</td>
                                    <td>{ formatarData(obj.data_prox_folga) }</td>
                                </tr>

                            ) ) }
                        </tbody>
                    ) }
                </table>
            </div>
        </div>
    )

}

export default FolgasCampo;