import './SidebarColab.css'

import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlineUnorderedList } from "react-icons/ai";

const SidebarColab = ({ state, changeState, squareState, changeSquareState, changeBenefitsState }) => {

    return(
        <div className={ state ? 'overlay active' : 'overlay disabled'} >

            <div className='sidebarMenu'>

                <MdOutlineClose id='closeSideBar' size={ 35 } onClick={ () => { changeState( !state ) } } />

                <div className='sidebarBtn' onClick={() => {
                        changeState( !state )
                        changeSquareState( !squareState )
                    }}> 
                    <div className='IconBtn' >
                        <IoAddCircleOutline size={ 30 } />
                    </div>
                    <p> NOVA FOLGA DE CAMPO</p> 

                </div>

                <div className='sidebarBtn' onClick={() => {

                        changeState( false );
                        changeBenefitsState( true );

                    }}> 
                    <div className='IconBtn' >
                        <AiOutlineUnorderedList size={ 25 } />
                    </div>
                    <p> LISTA DE BENEFÍCIOS</p> 

                </div>

            </div>
            
        </div>
    )
}

export default SidebarColab;