import './OverlayListBenefits.css';

import axios from 'axios';

import { useCallback, useEffect, useState } from 'react';
import { formatarData } from '../../Funcoes/FormatarData';
import { FaTrash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useParams } from 'react-router-dom';


const OverlayListBenefits = ({ overlayState, changeOverlayState, setBenefitsAddState, setFormValues, formValues }) => {

    const [ laodingData, setLoadingData ] = useState( true )
    const [ benefitsList, setBenefitsList ] = useState([]);

    const [ readyToSave, setReady ] = useState( false );

    let { matricula } = useParams();

    useEffect(() => {

        const fetch_benefits = async () => {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetColabBenefits/', {
                'matricula' : matricula
            })

            setBenefitsList( data );
            setLoadingData( false );

        }

        fetch_benefits();

    }, [ matricula ])

    const update_time = () => {

        const Now = new Date();

        let ano = Now.getFullYear();
        let mes = Now.getMonth() + 1;
        let dia = Now.getDate();
        let hora = Now.getHours();
        let minutos = Now.getMinutes();
        let segundos = Now.getSeconds();
                                
        function padToTwoDigits(number) {
            return number < 10 ? "0" + number : number;
        }
        
        mes = padToTwoDigits(mes);
        dia = padToTwoDigits(dia);
        hora = padToTwoDigits(hora);
        minutos = padToTwoDigits(minutos);
        segundos = padToTwoDigits(segundos);
        
        const data_preenchida = `${ano}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

        setFormValues( prevValues => ({

            ...prevValues,
            'data' : data_preenchida

        }))

    }

    const save_info = () => {

        update_time();

        setReady( true );

    }

    const save_form = useCallback( async ( ) => {

        try {

            const { data } = await axios.post( "https://sistemaintegrado.palmont.com.br/Painel/API/DeleteBenefit/", formValues );
            
            if( data.STATUS === "SUCCESS" ){

                window.location.reload();

            }
            
        } catch (error) {
            
            console.log( error )

        }

    }, [ formValues ])

    useEffect(() => {

        if( readyToSave ){

            save_form();
            setReady( false );

        }

    }, [ readyToSave, save_form ])

    return(
        <div className={ overlayState ? 'overlayListBenefits show' : 'overlayListBenefits hide' } >

            <div className='squareListBenefits' >

                <div className='benefitsListClose' >
                    <IoClose onClick={ () => { changeOverlayState( false ) } } />
                </div>

                <h3 className='benefits_list_title' > LISTA DE BENEFÍCIOS </h3>

                <div className='square_tabela_benefits' >
                    <table>
                        <thead>
                            <tr>
                                <th>TIPO</th>
                                <th>VALOR</th>
                                <th>INICIO</th>
                                <th>FINAL</th>
                                <th>STATUS</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                laodingData ?
                                ( 
                                    <tr>
                                        <td colSpan={ 5 } > CARREGANDO... </td>
                                    </tr>
                                ) : (
                                    <>
                                        { benefitsList.map( obj => (
                                            <tr key={ obj.id } onClick={ () => {

                                                setFormValues( prevValues => ({
                                                    ...prevValues,
                                                    'beneficio' : obj.tipo,
                                                    'data' : obj.data_inicio,
                                                    'valor' : obj.valor,
                                                    'id' : obj.id,
                                                    'func' : 'edit'
                                                }))

                                                changeOverlayState( false )
                                                setBenefitsAddState( true )

                                            } } >
                                                <td>{ obj.tipo }</td>
                                                <td>{ obj.valor }</td>
                                                <td>{ formatarData(obj.data_inicio) }</td>
                                                <td>{ obj.data_final ? formatarData( obj.data_final ) : '-' }</td>
                                                <td><div className={ obj.D_E_L_E_T_ ? 'inactive_benefit' : 'active_benefit' } ></div></td>
                                                <td className='trash_cel' onClick={ ( e ) => { 

                                                    e.stopPropagation();

                                                    setFormValues( prevValues => ({

                                                        ...prevValues,
                                                        'id' : obj.id

                                                    }))

                                                    save_info();

                                                } } ><FaTrash/></td>
                                            </tr>
                                        ) ) }
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <button className='btn_new_benefits' onClick={ () => {

                    setFormValues( prevValues => ({

                        ...prevValues,
                        'beneficio' : '',
                        'data' : '',
                        'valor' : '',
                        'id' : '',
                        'func' : 'create'

                    }))

                    changeOverlayState( false )
                    setBenefitsAddState( true )

                }} > ADICIONAR NOVO BENEFÍCIO </button>

            </div>

        </div>
    )

}

export default OverlayListBenefits;