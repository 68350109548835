import { useState } from 'react';
import InputCidades from '../InputCidades';
import InputEstados from '../InputEstados';
import InputMoney from '../InputMoney';
import InpuObrasAtivas from '../InputObrasAtivas';
import './OverlayNewDistance.css';

import { VscChromeClose } from "react-icons/vsc";
import { formatMoney } from "../../Funcoes/FormatMoney";
import axios from 'axios';

const OverlayNewDistance = ({ stateOverlay, changeStateOverlay, stateValues, changeStateValues, formFunc, editID }) => {

    const [ loading, setLoading ] = useState( false )

    const handleChange = (field) => (event) => {

        let { value } = event.target;
    
        if (field === 'valorPass' || field === 'valorAlim') {

            value = formatMoney( value );

        }
    
        changeStateValues(prevValues => ({

            ...prevValues,
            [field]: value

        }));

    };

    return(
        <div className={ stateOverlay ? 'overlayNewDist show' : 'overlayNewDist hide' } onClick={ () => { changeStateOverlay( !stateOverlay ) } } >

            <div className='squareNewDist' onClick={ ( event ) => {
                event.stopPropagation();
            } } >

                <div className='headerSquare' >
                    <VscChromeClose size={30} className='closeIcon' onClick={ () => { changeStateOverlay( !stateOverlay ) } } />
                </div>

                <form id='formulario' >

                    <div className='formField' >
                        <p> CENTRO CUSTO </p>
                        <InpuObrasAtivas obraState={ stateValues.obra } setObraState={ handleChange('obra') } />
                    </div>

                    <div className='formField' >
                        <p> ESTADO </p>
                        <InputEstados estadoState={ stateValues.estado } setEstadoState={ handleChange('estado') } />
                    </div>

                    <div className='formField' >
                        <p> MUNICIPIO </p>
                        <InputCidades estadoChosen={ stateValues.estado } municipState={ stateValues.municipio } setMunicipState={ handleChange('municipio') } />
                    </div>

                    <div className='formField' >
                        <p> DISTANCIA (KM) </p>
                        <input type='number' value={ stateValues.distancia } onChange={ handleChange('distancia') } />
                    </div>

                    <div className='formField' >
                        <p> DIAS PRAZO </p>
                        <input type='number' value={ stateValues.diasPrazo } onChange={ handleChange('diasPrazo') } />
                    </div>

                    <div className='formField' >
                        <p> GOZO FOLGA (DIAS UTEIS) </p>
                        <input type='number' value={ stateValues.gozoFolga } onChange={ handleChange('gozoFolga') } />
                    </div>

                    <div className='formField' >
                        <p> VALOR DA PASSAGEM </p>
                        <InputMoney valorState={ stateValues.valorPass } changeValorState={ handleChange('valorPass') } />
                    </div>

                    <div className='formField' >
                        <p> VALOR DA ALIMENTAÇÃO </p>
                        <InputMoney valorState={ stateValues.valorAlim } changeValorState={ handleChange('valorAlim') } />
                    </div>

                    <div className='formField' >
                        <button onClick={ async ( event ) => {
                            event.preventDefault();

                            setLoading( true )
                            let apiURL = ''

                            formFunc === 'create' ? apiURL = 'https://sistemaintegrado.palmont.com.br/Painel/API/SaveDistance/' : apiURL = 'https://sistemaintegrado.palmont.com.br/Painel/API/EditDistance/'

                            const { data } = await axios.post( apiURL , {
                                'obra' : stateValues.obra,
                                'estado' : stateValues.estado,
                                'municipio' : stateValues.municipio,
                                'distancia' : stateValues.distancia,
                                'diasPrazo' : stateValues.diasPrazo,
                                'diasFolga' : stateValues.gozoFolga,
                                'valorPass' : stateValues.valorPass,
                                'valorAlim' : stateValues.valorAlim,
                                'id': editID
                            });
                            console.log(data);

                            setLoading( false );

                            if( data.STATUS === "SUCCESS" ){

                                window.location.reload();

                            }

                        } } disabled={ loading } > { loading ? "CARREGANDO" : "SALVAR" } </button>

                    </div>

                </form>

            </div>

        </div>
    );
}

export default OverlayNewDistance;