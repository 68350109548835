import './RepsSquare.css'

const RepsSquare = ({ dados, changeIdentifier, changeShowState }) => {

    return(
        <div className='squareReps' >
            <table>
                <thead>
                    <tr>
                        <th>APELIDO</th>
                        <th>CENTRO CUSTO</th>
                        <th>LOGRADOURO</th>
                        <th>NÚMERO</th>
                        <th>COMPLEMENTO</th>
                        <th>BAIRRO</th>
                        <th>CIDADE</th>
                        <th>ESTADO</th>
                        <th>VAGAS</th>
                    </tr>
                </thead>
                <tbody>
                    { dados.map( obj => (

                        <tr key={ obj.identifier } onClick={ () => {
                            changeIdentifier( obj.identifier )
                            changeShowState( true )
                        } } >
                            <td> { obj.apelido_rep } </td>
                            <td> { obj.centro_custo } </td>
                            <td> { obj.logradouro } </td>
                            <td> { obj.numero } </td>
                            <td> { obj.complemento } </td>
                            <td> { obj.bairro } </td>
                            <td> { obj.cidade } </td>
                            <td> { obj.estado } </td>
                            <td> { obj.quantidade }/{ obj.vagas } </td>
                        </tr>

                    ) ) }
                </tbody>
            </table>
        </div>
    )

}

export default RepsSquare;