import './SelectAlojamento.css'

import axios from 'axios';
import { useState, useEffect } from 'react';

const SelectAlojamento = ({ aloj, changeAloj, obra }) => {

    const [ alojamentos, setAlojamentos ] = useState([])

    useEffect( () => {

        const getAlojamentos = async () => {

            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetReps/')
                setAlojamentos( data.filter( obj => obj.centro_custo === obra ) )

            }catch( error ){

                console.log( error )

            }

        }

        getAlojamentos();

    }, [ obra ])

    return(
        <select value={ aloj } onChange={ changeAloj } >
            <option value={''} >SELECIONE...</option>

            { alojamentos.map( obj => (

                <option value={ obj.identifier } >{ obj.apelido_rep + ' - ' + obj.logradouro + ' ' + obj.numero + ', ' + obj.bairro + ', ' + obj.cidade + ' ' + obj.estado }</option>

            ) ) }
            
        </select>
    )

}

export default SelectAlojamento;