import LoadingScreen from "../Componentes/LoadingScreen";

import axios from "axios";
import NavBar from "../Componentes/NavBar";
import BodyAfast from "../Componentes/BodyAfast";
import { useCallback, useEffect, useState } from "react";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";

const Afastamentos = () => {

    const [ afastados, setAfastados ] = useState([])
    const [ afastadosFiltered, setAfastadosFiltered ] = useState([])

    const [ overlay, setOverlay ] = useState( false )
    const [ loadingRelat, setLoadingRelat ] = useState( false );

    const getDados = async () => {

        const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetAfastados/')
        setAfastados( data )
        setAfastadosFiltered( data )
        console.log( data )

    }

    useEffect(() => {

        getDados();

    }, [])

    const handleFilter = useCallback(( nameFilter, matFilter, funcFilter, obraFilter, motivoFilter, statusFilter, inicioFilter, finalFilter ) => {

        const parseDate = (dateString) => {
            if (!dateString) return null;
            const parts = dateString.split('/');
            if (parts.length !== 3) return null;
            return new Date(parts[2], parts[1] - 1, parts[0]);
        };

        const filtered = afastados.filter(item => {

            const data_inicio = parseDate(item.DATA_INICIO);
            const data_final_item = parseDate(item.DATA_FIM);
    
            const name_filter = !nameFilter || item.NOME.toUpperCase().includes(nameFilter.toUpperCase());
            const mat_filter = !matFilter || item.MATRICULA.includes(matFilter);

            const func_filter = funcFilter.length === 0 || funcFilter.some( func => item.FUNCAO.includes( func ) )
            const obra_filter = obraFilter.length === 0 || obraFilter.some( obra => item.CENTRO_CUSTO.includes( obra ) )
            const motivo_filter = motivoFilter.length === 0 || motivoFilter.some(motivo => (item.MOTIVO_AFA || '').includes(motivo));
            const status_filter = statusFilter.length === 0 || statusFilter.some( status => item.STATUS.includes( status ) )

            const filter_inicio = !inicioFilter || (data_inicio && new Date(data_inicio) >= new Date(inicioFilter));
            const filter_final = !finalFilter || (data_final_item && new Date(data_final_item) <= new Date(finalFilter));
    

            return name_filter && mat_filter && filter_inicio && filter_final  && func_filter && obra_filter && motivo_filter && status_filter ;

        });
    
        setAfastadosFiltered(filtered);
    
    }, [afastados]);

    const funcSet = new Set(afastados.map(obj => obj.FUNCAO).sort());
    const funcoes = Array.from(funcSet).map(obj => ({ label: obj, value: obj }));

    const obrasSet = new Set(afastados.map(obj => obj.CENTRO_CUSTO).sort())
    const obras = Array.from(obrasSet).map(obj => ({ label: obj, value: obj }))

    const motivosSet = new Set( afastados.map( obj => obj.MOTIVO_AFA ).sort())
    const motivos = Array.from(motivosSet).map( obj => ({ label: obj, value: obj }))

    const getRelat = async () => {

        setLoadingRelat( true )

        try{

            const { data } = await axios.post( 'https://sistemaintegrado.palmont.com.br/Painel/API/GenerateRelat/', afastadosFiltered,
            {
                responseType: 'blob'
            })
            
            const url = window.URL.createObjectURL(new Blob([ data ]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relatorio.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }catch( error ){

            console.log( error )

        }finally{

            setLoadingRelat( false )

        }

    }

    const showOverlay = () => {

        setOverlay( true )

    }


    return(
        <>
            <NavBar
                setSideBar={ showOverlay }
                title='AFASTAMENTOS'
            />

            <BodyAfast
                dados={ afastadosFiltered }
                onFilter={ handleFilter }
                funcoes={ funcoes }
                obras={ obras }
                motivos={ motivos }
            />

            <Overlay
                state={ overlay }
                changeState={ setOverlay }
            >
                <SideMenu>
                    <SideMenuBtn
                        button="GERAR RELATÓRIO"
                        clickFunc={ getRelat }
                        isLoading={ loadingRelat }
                    />
                </SideMenu>
            </Overlay>


        </>
    )

}

export default Afastamentos;