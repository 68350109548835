import axios from 'axios';
import './ContentReps.css'

import { useState, useEffect, useCallback } from 'react';
import RepsSquare from '../RepsSquare';
import RepsHeader from '../RepsHeader';

const ContentReps = ({ changeIdentifier, changeShowState }) => {

    const [ republicas, setRepublicas ] = useState([])
    const [ filterReps, setFilterReps ] = useState([])

    useEffect( () => {

        const getRepublicas = async () => {

            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetReps/')

                setRepublicas( data )
                setFilterReps( data )

            }catch( error ){

                console.log( error )

            }

        }

        getRepublicas();

    }, []);

    const handleFilter = useCallback(( filterObra, filterBairro, filterCidade, filterEstado ) => {

        const filtered = republicas.filter( item => {

            const obra_filter = !filterObra || item.centro_custo === filterObra
            const bairro_filter = !filterBairro || item.bairro === filterBairro
            const cidade_filter = !filterCidade || item.cidade === filterCidade
            const estado_filter = !filterEstado || item.estado === filterEstado

            return obra_filter && bairro_filter && cidade_filter && estado_filter

        })

        setFilterReps( filtered )

    }, [ republicas ])

    return(

        <div className='contentReps' >

            <RepsHeader dados={ filterReps } filter={ handleFilter } />
            
            <RepsSquare dados={ filterReps } changeIdentifier={ changeIdentifier } changeShowState={ changeShowState } />

        </div>

    )

}

export default ContentReps;