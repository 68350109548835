import './RepsHeader.css';

import { useState, useEffect } from 'react';

const RepsHeader = ({ dados, filter }) => {

    const [ vagasLivres, setVagasLivres ] = useState( 0 );
    const [ vagasPreenc, setVagasPreenc ] = useState( 0 );

    useEffect(() => {

        let total_quant = dados.reduce(( acc, obj ) => acc + Number( obj.quantidade ), 0 )
        let total_vagas = dados.reduce(( acc, obj ) => acc + Number( obj.vagas ), 0 )

        setVagasLivres( total_quant )
        setVagasPreenc( total_vagas )

    },[ dados ])

    const [ filterObra, setFilterObra ] = useState('')
    const [ filterBairro, setFilterBairro ] = useState('')
    const [ filterCidade, setFilterCidade ] = useState('')
    const [ filterEstado, setFilterEstado ] = useState('')

    useEffect(() => {

        filter( filterObra, filterBairro, filterCidade, filterEstado )

    },[ filterObra, filterBairro, filterCidade, filterEstado, filter ])


    console.log( dados )

    return(
        <div className='repsHeader' >

            <div className='repsFilters' >

                <div className='filter-row-1' >

                    <select value={ filterObra } onChange={ ( e ) => {

                        let { value } = e.target

                        setFilterObra( value )

                    } } >
                        <option value={''} >CENTRO CUSTO...</option>

                        { [ ...new Set( dados.map( obj => obj.centro_custo ) ) ].sort().map( obra => (
                            <option value={ obra } key={ obra } >{ obra }</option>
                        ) ) }

                    </select>

                    <select value={ filterBairro } onChange={ ( e ) => {

                        let { value } = e.target
                        setFilterBairro( value )

                    } } >
                        <option value={''} >BAIRRO...</option>

                        { [ ...new Set( dados.map( obj => obj.bairro ) ) ].sort().map( obra => (
                            <option value={ obra } key={ obra } >{ obra }</option>
                        ) ) }

                    </select>

                </div>

                <div className='filter-row-2' >

                    <select value={ filterCidade } onChange={ ( e ) => {
                        
                        let { value } = e.target

                        setFilterCidade( value );

                    } } >
                        <option value={''} >CIDADE...</option>

                        { [ ...new Set( dados.map( obj => obj.cidade ) ) ].sort().map( obra => (
                            <option value={ obra } key={ obra } >{ obra }</option>
                        ) ) }

                    </select>

                    <select value={ filterEstado } onChange={ ( e ) => {

                        let { value } = e.target

                        setFilterEstado( value );

                    } } >
                        <option value={''} >ESTADO...</option>

                        { [ ...new Set( dados.map( obj => obj.estado ) ) ].sort().map( obra => (
                            <option value={ obra } key={ obra } >{ obra }</option>
                        ) ) }
                    </select>

                </div>

            </div>

            <div className='repsStats' >

                <div className='qtdVagasRep' >

                    <p>Vagas - { vagasLivres }/{ vagasPreenc }</p>

                </div>
            
            </div>

        </div>
    )

}

export default RepsHeader;