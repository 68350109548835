import NewAdmField from '../NewAdmField';
import './FormNewAdm.css'

import { formatCPF } from '../../Funcoes/FormatCPF';
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { formatTelefone } from '../../Funcoes/FormatTelefone';
import InpuObrasAtivas from '../InputObrasAtivas';

const FormNewAdm = ({ formValues, changeValues, requestStatus }) => {

    const [ funcList, setFuncList ] = useState([]);
    const [ generalMunicipEstado, setMinicipEstado ] = useState([])

    const [ estados, setEstados ] = useState([])
    const [ municipios, setMunicipios ] = useState([])

    const [ loadBtn, setLoadBtn ] = useState( false );
    const [ readyToSave, setReady ] = useState( false );

    useEffect(() => {

        const search_func = async () => {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetFunctions/')

            setFuncList( data )

        }

        const search_municip = async () => {

            const { data } = await axios('https://servicodados.ibge.gov.br/api/v1/localidades/municipios')
            console.log( data )

            setMinicipEstado( data );

        }

        search_func();
        search_municip();
        
    }, [])

    useEffect(() => {

        const estados = []

        generalMunicipEstado.forEach( municipio => {

            const estado = municipio.microrregiao.mesorregiao.UF.sigla

            if( !estados.find( e => e === estado ) ){

                estados.push( estado )

            }

        })

        estados.sort();

        setEstados( estados );

    }, [ generalMunicipEstado ])

    useEffect(() => {

        const municipios = []

        generalMunicipEstado.forEach( municipio => {

            const estado = municipio.microrregiao.mesorregiao.UF.sigla
            const municipio_name = municipio.nome

            if( estado === formValues.estado ){
                
                municipios.push( municipio_name.toUpperCase() );

            }

        })

        setMunicipios( municipios.sort() )

    }, [ formValues, generalMunicipEstado ])

    const options = [
        "SIM"
    ];

    const handleChange = ( field ) => ( event ) => {

        let { value } = event.target

        value = field === 'nome' ? value.toUpperCase() : value;
        value = field === 'cpf' ? formatCPF( value ) : value;
        value = field === 'telefone' ? formatTelefone( value ) : value;
        value = field === 'num_calcado' ? parseInt( value ) : value;
        
        if (field === 'estado') {
            changeValues((prevValues) => ({
                ...prevValues,
                municip: '',
            }));
        }

        if( field === 'num_calcado' ){

            if( value === '' ){
                value = 0
            }

        }
 
        changeValues( prevValues => ({
            ...prevValues,
            [ field ]: value
        }))

    }

    const save_form = useCallback( async () => {

        let sql = ''

        requestStatus === 'create' ? sql = 'https://sistemaintegrado.palmont.com.br/Painel/API/SaveAdmissao/' : sql = 'https://sistemaintegrado.palmont.com.br/Painel/API/EditAdmissao/'

        try {

            const { data } = await axios.post( sql , formValues );
            console.log(data);

            if( data.STATUS === 'SUCCESS' ){

                window.location.reload();

            }

        } catch (error) {

            console.log(error);

        }

        console.log( formValues );

        setLoadBtn( false );

    }, [ formValues, requestStatus ]);

    const update_time = () => {

        const Now = new Date();

        let ano = Now.getFullYear();
        let mes = Now.getMonth() + 1;
        let dia = Now.getDate();
        let hora = Now.getHours();
        let minutos = Now.getMinutes();
        let segundos = Now.getSeconds();
                                
        function padToTwoDigits(number) {
            return number < 10 ? "0" + number : number;
        }
        
        mes = padToTwoDigits(mes);
        dia = padToTwoDigits(dia);
        hora = padToTwoDigits(hora);
        minutos = padToTwoDigits(minutos);
        segundos = padToTwoDigits(segundos);
        
        const data_preenchida = `${ano}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

        changeValues( prevValues => ({

            ...prevValues,
            'preenchido' : data_preenchida

        }))

    }

    const save_admissao = ( e ) => {

        e.preventDefault();

        setLoadBtn( true );

        update_time();
        setReady( true );

    }

    useEffect(() => {

        if( readyToSave ){

            save_form();
            setReady( false );

        }

    }, [ readyToSave, save_form ]);

    return(
        <form className={ "formularioNewAdm" } >

            <div className='newAdmField' >
        
                <p>CENTRO CUSTO</p>
        
                <div className='testeDiv' >
        
                    <InpuObrasAtivas obraState={ formValues.centro_custo } setObraState={ handleChange('centro_custo') } />
        
                </div>
        
            </div>

            <NewAdmField
                type={'input'}
                inputType={'text'}
                placeholder={'NOME:'}
                title={'NOME'}
                funcao={ handleChange('nome') }
                value={ formValues.nome }
            />

            <NewAdmField
                type={'input'}
                inputType={'text'}
                placeholder={'CPF:'}
                title={'CPF'}
                funcao={ handleChange('cpf') }
                value={ formValues.cpf }
            />

            <NewAdmField
                type={'select'}
                title={'FUNÇÃO'}
                options={ funcList }
                funcao={ handleChange('funcao') }
                value={ formValues.funcao }
            />

            <NewAdmField
                type={'select'}
                title={'DESITENCIA'}
                options={ options }
                funcao={ handleChange('desistencia') }
                value={ formValues.desistencia }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'PREVISÃO DE CHEGADA'}
                funcao={ handleChange('prev_chegada') }
                value={ formValues.prev_chegada }
            />

            <NewAdmField
                type={'textarea'}
                title={'OBSERVAÇÕES'}
                funcao={ handleChange('obs') }
                value={ formValues.obs }
            />

            <NewAdmField
                type={'input'}
                inputType={'text'}
                placeholder={'( XX ) XXXXX-XXXX'}
                title={'TELEFONE'}
                funcao={ handleChange( 'telefone' ) }
                value={ formValues.telefone }
            />

            <NewAdmField 
                type={'select'}
                title={'ESTADO DE ORIGEM'}
                options={ estados }
                funcao={ handleChange('estado') }
                value={ formValues.estado }
            />

            <NewAdmField 
                type={'select'}
                title={'MUNICÍPIO DE ORIGEM'}
                options={ municipios }
                funcao={ handleChange('municip') }
                value={ formValues.municip }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'RECEBIMENTO DO CURRICULO'}
                funcao={ handleChange('receb_curr') }
                value={ formValues.receb_curr }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'DATA CONTATO'}
                funcao={ handleChange('data_contato') }
                value={ formValues.data_contato }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'CHEGADA'}
                funcao={ handleChange('data_chegada') }
                value={ formValues.data_chegada }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'EXAME ADMISSIONAL'}
                funcao={ handleChange('data_exame') }
                value={ formValues.data_exame }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'DOC. ADMISSÃO'}
                funcao={ handleChange('doc_admissao') }
                value={ formValues.doc_admissao }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'DOC. SESMT'}
                funcao={ handleChange('doc_sesmt') }
                value={ formValues.doc_sesmt }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'ADMISSÃO'}
                funcao={ handleChange('admissao') }
                value={ formValues.admissao }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'CADASTRO PORTAL'}
                funcao={ handleChange('cadastro_portal') }
                value={ formValues.cadastro_portal }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'INTEGRAÇÃO'}
                funcao={ handleChange('integracao') }
                value={ formValues.integracao }
            />

            <NewAdmField
                type={'input'}
                inputType={'date'}
                title={'ENTRADA NO SITE'}
                funcao={ handleChange('entrada_site') }
                value={ formValues.entrada_site }
            />

            <NewAdmField 
                type={'select'}
                options={['P', 'M', 'G']}
                title={'NÚMERO UNIFORME'}
                funcao={ handleChange('num_uniforme') }
                value={ formValues.num_uniforme }
            />

            <NewAdmField
                type={'input'}
                inputType={'number'}
                title={'NÚMERO CALÇADO'}
                funcao={ handleChange('num_calcado') }
                value={ formValues.num_calcado }
            />

            <NewAdmField
                type={'input'}
                inputType={'submit'}
                funcao={ save_admissao }
                loadBtn={ loadBtn }
            />

        </form>

    )

}

export default FormNewAdm;