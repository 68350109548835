import style from './FormContainer.module.css';

import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const FormContainer = ({ closeFunc, children, changeContent, loadingSave }) => {

    const clickClose = () => {

        closeFunc( false )

        if( changeContent ){

            changeContent();

        }

    }

    return(
        <div className={ style.formContainer } onClick={ ( e ) => { e.stopPropagation() } } >

            <div className={ style.closeContainer } >

                { <IoCloseOutline onClick={ () => { clickClose() } } /> }

            </div>

            { children }

            <div className={ loadingSave ? style.saveChanges : style.waitChanges } >

                <AiOutlineLoading3Quarters size={ 40 } />

            </div>

        </div>
    )

}

export default FormContainer;