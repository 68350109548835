import { useEffect, useState } from 'react';
import FilterChild from '../FilterChild';
import FilterInput from '../FilterInput';
import FilterSelect from '../FilterSelect';
import style from './FiltersContainer.module.css';

const FiltersContainer = ({ obras, funcoes, tipos, onFilter }) => {

    const [ nameFilter, setNameFilter ] = useState('')
    const [ cpfFilter, setCpfFilter ] = useState('')
    const [ obraFilter, setObraFilter ] = useState('')
    const [ funcFilter, setFuncFilter ] = useState('')
    const [ tipoFilter, setTipoFilter ] = useState('')
    const [ dateInitFilter, setDateInitFilter ] = useState('')
    const [ dateEndFilter, setDateEndFilter ] = useState('')

    useEffect(() => {

        onFilter( nameFilter, cpfFilter, obraFilter, funcFilter, tipoFilter, dateInitFilter, dateEndFilter );

    },[ onFilter, nameFilter, cpfFilter, obraFilter, funcFilter, tipoFilter, dateInitFilter, dateEndFilter ])

    return(
        <div className={ style.filtersContainer } >

            <FilterChild>
                <FilterInput
                    type={ 'text' }
                    placeholder={'NOME:'}
                    value={ nameFilter }
                    onChange={ e => setNameFilter( e.target.value ) }
                />
            </FilterChild>

            <FilterChild>
                <FilterInput
                    type={ 'text' }
                    placeholder={'CPF:'}
                    value={ cpfFilter }
                    onChange={ e => setCpfFilter( e.target.value ) }
                />
            </FilterChild>

            <FilterChild>
                <FilterSelect
                    options={ obras }
                    placeholder={ 'CENTRO CUSTO...' }
                    value={ obraFilter }
                    onChange={ e => setObraFilter( e.target.value ) }
                />
            </FilterChild>

            <FilterChild>
                <FilterSelect
                    options={ funcoes }
                    placeholder={ 'FUNÇÃO...' }
                    value={ funcFilter }
                    onChange={ e => setFuncFilter( e.target.value ) }
                />
            </FilterChild>

            <FilterChild>
                <FilterSelect
                    options={ tipos }
                    placeholder={ 'TIPO DO ASO...' }
                    value={ tipoFilter }
                    onChange={ e => setTipoFilter( e.target.value ) }
                />
            </FilterChild>

            <FilterChild>
                <FilterInput onChange={ e => setDateInitFilter( e.target.value ) } type={ 'date' } />
                <FilterInput onChange={ e => setDateEndFilter( e.target.value ) } type={ 'date' } />
            </FilterChild>

        </div>
    )
}

export default FiltersContainer;