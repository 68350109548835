import FiltersContainer from '../FiltersContainer';
import StatsContainer from '../StatsContainer';
import style from './HeaderContainer.module.css';

const HeaderContainer = ({ dados, onFilter }) => {

    const obras = [ ...new Set( dados.map( obj => obj.obra ) ) ].sort()
    const funcoes = [ ...new Set( dados.map( obj => obj.funcao ) ) ].sort()
    const tipos = [ ...new Set( dados.map( obj => obj.aso ) ) ].sort()

    return( 
        <div className={ style.headerContainer } >
            
            <FiltersContainer obras={ obras } funcoes={ funcoes } tipos={ tipos } onFilter={ onFilter } />
            
            <StatsContainer dados={ dados } />

        </div>
    )
}

export default HeaderContainer;