import axios from "axios";
import { useState, useEffect } from "react";

const InputCidades = ({ estadoChosen, municipState, setMunicipState }) => {

    const [ municipios, setMunicipios ] = useState([])

    useEffect( () => {

        const getMunicipios = async () => {

            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetMunicipios/')

                setMunicipios( data )

            }catch( error ){

                console.log( error )

            }

        }

        getMunicipios();

    }, [])

    console.log( municipios.filter( item => item.RA_ESTADO === estadoChosen ) )

    if( estadoChosen === '' ){



        return(

            <select>

                <option value={''} > CARREGANDO... </option>

            </select>

        )

    }else{

        let municipiosFiltrado = municipios.filter( item => item.RA_ESTADO === estadoChosen )

        return(
            <select value={ municipState } onChange={ setMunicipState } >
    
                <option value={''} > SELECIONE... </option>

                { municipiosFiltrado.map( obj => ( 
                    
                    <option key={ obj.MUNICIPIO } value={ obj.MUNICIPIO }>{ obj.MUNICIPIO }</option>

                ) ) }
    
            </select>
        )

    }

}

export default InputCidades;