import './AdmissaoSquare.css';

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { formatCPF } from '../../Funcoes/FormatCPF';
import { formatTelefone } from '../../Funcoes/FormatTelefone';
import { formatarData } from '../../Funcoes/FormatarData';

const AdmissaoSquare = ({ tabelaValues, loadAdmission, setFormValues, changeState, user, setRequestStatus }) => {

    console.log( tabelaValues );

    return(
        <div className='admissaoSquare' >

            <table>
                <thead>
                    <tr>
                        <th>CENTRO CUSTO:</th>
                        <th>NOME:</th>
                        <th>CPF:</th>
                        <th>FUNÇÃO:</th>
                        <th>STATUS:</th>
                        <th>DATA:</th>
                        <th>DESISTÊNCIA:</th>
                    </tr>
                </thead>

                <tbody>
                    { tabelaValues.map( ( obj ) => (
                        <tr key={ obj.id } onClick={ () => {

                            setRequestStatus( 'edit' );
                            setFormValues({

                                'id' : obj.id,
                                'nome': obj.nome,
                                'cpf' : obj.cpf ? formatCPF( obj.cpf ) : '',
                                'funcao' : obj.vaga_funcao,
                                'desistencia' : obj.desistencia_mot,
                                'prev_chegada' : obj.prev_chegada,
                                'obs' : obj.observacoes,
                                'telefone' : obj.telefone ? formatTelefone( obj.telefone ) : '',
                                'estado' : obj.estado_origem,
                                'municip' : obj.municip_origem,
                                'receb_curr' : obj.receb_curriculo,
                                'data_contato' : obj.data_contato,
                                'data_chegada' : obj.data_chegada,
                                'data_exame' : obj.data_exame,
                                'doc_admissao' : obj.data_doc_adm,
                                'doc_sesmt' : obj.data_doc_sesmt,
                                'admissao' : obj.data_admissao,
                                'cadastro_portal' : obj.data_cad_portal,
                                'integracao' : obj.data_integracao,
                                'entrada_site' : obj.data_entrada_site,
                                'num_uniforme' : obj.num_uniforme,
                                'num_calcado' : obj.num_calcado,
                                'user' : user.USER,
                                'preenchido' : '',
                                'centro_custo' : obj.centro_custo

                            })

                            changeState( true );

                         } } >
                            <td> { obj.centro_custo ? obj.centro_custo : '-' } </td>
                            <td> { obj.nome } </td>
                            <td> { formatCPF( obj.cpf ) } </td>
                            <td> { obj.vaga_funcao ? obj.vaga_funcao : '-' } </td>
                            <td> { obj.last_key ? obj.last_key : '-' } </td>
                            <td> { obj.last_date ? formatarData( obj.last_date ) : '-' } </td>
                            <td> { obj.desistencia_mot ? obj.desistencia_mot : '-' } </td>
                        </tr>
                    ) ) }
                </tbody>
            </table>

            <div className={ loadAdmission ? 'loadingTable' : 'loadingTable hide' }>
                <AiOutlineLoading3Quarters />
            </div>

        </div>
    )

}

export default AdmissaoSquare;