import './ImagemLogin.css'

const ImagemLogin = () => {

    return(

        <div className='containerImg' >
            <img src={'/Images/PREPARO_DE_MADEIRA.jpg'}  alt='fundoPalmont' />
        </div>

    )

}

export default ImagemLogin