import axios from "axios";
import { useState, useEffect } from "react";

const InputEstados = ({ estadoState, setEstadoState }) => {

    const [ estados, setEstados ] = useState([])

    useEffect( () => {

        const getEstados = async () => {

            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetEstados/')

                setEstados( data )

            }catch( error ){

                console.log( error )

            }

        }

        getEstados();

    }, [])

    if( estados.length === 0 ){

        return(

            <select>
                <option value={''} > CARREGANDO... </option>
            </select>

        )

    }else{

        return(
            <select onChange={ setEstadoState } value={ estadoState } >
    
                <option value={''} > SELECIONE... </option>
    
                { estados.map( obj => ( 
                    
                    <option value={ obj.RA_ESTADO }>{ obj.RA_ESTADO }</option>
    
                ) ) }
    
            </select>
        )

    }
}

export default InputEstados;