import './LoginContent.css'

import Cookies from 'js-cookie'
import axios from 'axios';

import { useState } from 'react';
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const LoginContent = () => {

    const [ email, setEmail ] = useState('')
    const [ senha, setSenha ] = useState('')

    const [showPass, setShowPass] = useState(false);

    const [ btnValue, setBtnValue ] = useState('ENTRAR')
    const [ btnDisabled, setBtnDisabled ] = useState(false)

    const navigate = useNavigate();

    const tooglePass = () => {

        setShowPass( !showPass )

    }


    const inputEmail = ( event ) => {

        setEmail( event.target.value )

    }

    const inputPass = ( event ) => {

        setSenha( event.target.value )

    }

    const login = async () => {

        if( email === '' ){

            alert('PREENCHA O E-MAIL')

        }else if( senha === '' ){

            alert('PREENCHA A SENHA')

        }else{

            setBtnValue( <AiOutlineLoading3Quarters className='loadingIcon' /> )
            setBtnDisabled( true )

            try{

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/assets/php/checkUser.php', {
                    email : email,
                    senha : senha
                })
                
                if( data.status === false ){

                    toast.error("Usuário ou senha incorretos.");

                }else{

                    Cookies.set('token', data.token)

                    navigate('/')

                }

            }catch( error ){

                console.log( error )

            }

            setBtnValue('ENTRAR')
            setBtnDisabled( false )

        }

    }

    return(
        <div className='loginContent' >
            <img className='logoPalmont' src='/Images/Logo.png' alt='palmontLogo' />
            <div className='containerLogin' >

                <input className='emailLogin' type='email' placeholder='E-mail:' value={ email } onInput={ inputEmail } />
                <div className='loginPass' >
                    <input
                        type={ showPass ? 'text' : 'password' }
                        placeholder='Senha:'
                        value={ senha }
                        onInput={ inputPass }
                    />
                    <button id='btnShowPass' onClick={ tooglePass } >
                        {showPass ? <LuEyeOff size={27} /> : <LuEye size={27} />}
                    </button>
                </div>
                <p id='forgotPass' >ESQUECEU A SENHA ?</p>
                <button id='enterBtn' onClick={ login } disabled={ btnDisabled } >{ btnValue }</button>
                <div id='registerBtn' > <p>CADASTRAR</p> </div>
            </div>
            <ToastContainer
                    position="top-left"
                    theme="dark"
                    autoClose={2000}
                    toastClassName={"my-toast"}
            ></ToastContainer>
        </div>
    )

}

export default LoginContent;