import { useCallback, useEffect, useState } from "react";
import NavBar from "../Componentes/NavBar";
import RecisoesBody from "../Componentes/RecisoesBody";
import axios from "axios";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";

const Recisoes = () => {
    const [recisoes, setRecisoes] = useState([]);
    const [recisoesFilter, setRecisoesFilter] = useState([]);

    const [ loadingRelat, setLoadingRelat ] = useState( false );

    const [ overlay, setOverlay ] = useState( false );

    const obrasSet = new Set(recisoes.map(obj => obj.CENTRO_CUSTO).sort());
    const obras = Array.from(obrasSet).map(obj => ({ label: obj, value: obj }));

    const funcoesSet = new Set( recisoes.map(obj => obj.FUNCAO ).sort())
    const funcoes = Array.from( funcoesSet ).map( obj => ({ label: obj, value: obj }))

    const encSet = new Set( recisoes.map( obj => obj.ENCARREGADO ).sort() )
    const encarregados = Array.from( encSet ).map( obj => ({ label: obj, value: obj }))

    const handleFilter = useCallback((filterName, filterMat, filterFunc, filterObra, filterEnc, filterSit, filterStat, filterPeriodo) => {

        const filtered = recisoes.filter(item => {
            const name_filter = !filterName || item.NOME.toUpperCase().includes(filterName.toUpperCase());
            const mat_filter = !filterMat || item.MATRICULA.includes(filterMat);
            const func_filter = filterFunc.length === 0 || filterFunc.some(func => item.FUNCAO.includes(func));
            const obra_filter = filterObra.length === 0 || filterObra.some(obra => item.CENTRO_CUSTO.includes(obra));
            const enc_filter = filterEnc.length === 0 || filterEnc.some( enc => ( item.ENCARREGADO || '' ).includes(enc));
            const sit_filter = !filterSit || item.SITUACAO === filterSit;
            const stat_filter = !filterStat || item.STATUS === filterStat;

            const periodo_filter = () => {
                if (!filterPeriodo) return true;

                if (filterPeriodo === '60-90') {
                    return item.DIAS_ADMISSAO >= 60 && item.DIAS_ADMISSAO <= 90;
                } else if (filterPeriodo === '90-120') {
                    return item.DIAS_ADMISSAO >= 90 && item.DIAS_ADMISSAO <= 120;
                }

                return false;

            };

            return name_filter && mat_filter && func_filter && obra_filter && enc_filter && sit_filter && stat_filter && periodo_filter();
            
        });

        setRecisoesFilter(filtered);
    }, [recisoes]);

    useEffect(() => {
        const getRecisoes = async () => {
            try {
                const { data } = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/API/GetRecisoes/");
                console.log(data);

                setRecisoes(data);
                setRecisoesFilter(data);
            } catch (error) {
                console.error(error);
            }
        };

        getRecisoes();
    }, []);

    const getRelat = async () => {

        setLoadingRelat( true );

        try{

            const { data } = await axios.post( 'https://sistemaintegrado.palmont.com.br/Painel/API/GenerateRelatRecisoes/', recisoesFilter,
            {
                responseType: 'blob'
            })
            
            const url = window.URL.createObjectURL(new Blob([ data ]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relatorio.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }catch( error ){

            console.log( error )

        }finally{

            setLoadingRelat( false );

        }

    }

    const showOverlay = () => {

        setOverlay( true )

    }

    return (
        <>
            <NavBar
                title='RECISOES'
                setSideBar={ showOverlay }

            />
            <RecisoesBody
                onFilter={handleFilter}
                dados={recisoesFilter}
                obras={obras}
                funcoes={funcoes}
                encarregados={encarregados}
            />

            <Overlay
                state={ overlay }
                changeState={ setOverlay }
            >
                <SideMenu>
                    <SideMenuBtn
                        button='GERAR RELATÓRIO'
                        clickFunc={ getRelat }
                        isLoading={ loadingRelat }
                    />
                </SideMenu>
            </Overlay>

        </>
    );
};

export default Recisoes;
