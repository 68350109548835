import "./TabelaColabs.css";

import TableBody from "../TableBody";
import TableHeader from "../TableHeader";

import React, { useState, useRef } from 'react';

const TabelaColabs = ( { colabs } ) => {

    const [final, setFinal] = useState(100);

    const squareRef = useRef(null);

    const handleScroll = () => {

        const { scrollTop, clientHeight, scrollHeight } = squareRef.current;

        if (scrollTop + clientHeight === scrollHeight) {

            setFinal(final + 100);

        }

    };

    return (
        <div className='square' ref={squareRef} onScroll={handleScroll} >
            <table>
                <TableHeader />
                <tbody>
                    <TableBody data={colabs} final={final} />
                </tbody>
            </table>
        </div>
    );
};

export default TabelaColabs;