import './OverlayPagamento.css';
import InputObrasAtivas from '../InputObrasAtivas'

import InputMoney from '../InputMoney';
import axios from 'axios';

import { useEffect, useState, useCallback } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import { formatMoney } from "../../Funcoes/FormatMoney";
import { ToastContainer, toast } from 'react-toastify';

const OverlayPagamento = ({ user, overlayPag, changeOverlayPag, formValues, setFormValues, motivos }) => {

  const [readyToSave, setReadyToSave] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const [nome, setNome] = useState('')

  const notify = (text, status) => {

    if (status === 'SUCCESS') {

      toast.success(text)

    } else {

      toast.error(text)

    }

  }

  const isValidForm = (values) => {
    return Object.values(values).every(value => value !== null && value !== '' && value !== '0.00');
  };

  useEffect(() => {

    const save_pag = async () => {

      setBtnLoading(true)

      try {

        const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Teste/', formValues); //Alterar nome do endpoint!!!

        if (data.STATUS === 'SUCCESS') {

          notify('INFORMAÇÕES SALVAS', 'SUCCESS')
          window.location.reload();

        } else {

          notify(data.ERROR, 'ERROR')

        }

      } catch (error) {

        notify(error, 'ERROR')

      }

      setBtnLoading(false)

    }

    if (readyToSave) {

      if (isValidForm(formValues)) {

        save_pag();

      } else {

        console.log('Itens faltando')

      }

      setReadyToSave(false)

    }

  }, [readyToSave, formValues])

  useEffect(() => {

    setFormValues(prevValues => ({

      ...prevValues,
      user: user

    }));

  }, [])

  const handleChange = (field) => (event) => {

    let { value } = event.target;

    if (field === 'matricula') {

      if (/^\d*$/.test(value) && value.length <= 6) {

        setFormValues(prevValues => ({

          ...prevValues,
          [field]: value

        }));

      }

    } else if (field === 'valor') {

      value = formatMoney(value);

      setFormValues(prevValues => ({

        ...prevValues,
        [field]: value

      }));

    } else {

      setFormValues(prevValues => ({

        ...prevValues,
        [field]: value

      }));

    }

  }

  useEffect(() => {

    if (formValues.matricula.length === 6) {

      const buscar_colab = async () => {

        setNome('CARREGANDO...')

        try {

          const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetColabName/', {
            matricula: formValues.matricula
          })

          if (data.STATUS === 'SUCCESS') {

            setNome(data.NOME)

          } else {

            setNome('COLABORADOR NÃO ENCONTRADO')

          }

        } catch (error) {

          console.log(error)

        }

      }

      buscar_colab();

    } else {

      setNome('')

    }

  }, [formValues.matricula])

  const save_pag = useCallback(() => {

    setReadyToSave(true);

  }, []);

  return (
    <div className={overlayPag ? 'overlayNewPagamento show' : 'overlayNewPagamento hide'} onClick={() => {
      changeOverlayPag(false)
    }} >

      <div className='squareNewPag' onClick={(event) => {
        event.stopPropagation();
      }} >

        <div className='headerNewPag' >
          <IoCloseOutline size={38} />
        </div>

        <div className='bodyNewPag' >

          <form id='form_pag' >

            <div className='form_pag_field' >

              <p>CENTRO CUSTO</p>

              <div className='form_pag_cont' >
                <InputObrasAtivas obraState={formValues.obra} setObraState={handleChange('obra')} />
              </div>

            </div>

            <div className='form_pag_field' >

              <p>MOTIVO</p>

              <div className='form_pag_cont' >
                <select value={formValues.motivo} onChange={handleChange('motivo')} required >
                  <option value={''} > SELECIONE... </option>
                  {motivos.map(value => (
                    <option value={value}  >{value}</option>
                  ))}
                </select>
              </div>

            </div>

            <div className='form_pag_field' >

              <p>MATRICULA</p>

              <div className='form_pag_cont' >
                <input value={formValues.matricula} onChange={handleChange('matricula')} placeholder='EX: 001234' />
              </div>

            </div>

            <div className='form_pag_field' >

              <p>COLABORADOR</p>

              <div className='form_pag_cont' >
                <input placeholder='COLABORADOR...' value={nome} readOnly />
              </div>

            </div>

            <div className='form_pag_field' >

              <p>VALOR</p>

              <div className='form_pag_cont' >
                <InputMoney valorState={formValues.valor} changeValorState={handleChange('valor')} />
              </div>

            </div>

            <div className='form_pag_field' >

              <p>DATA</p>

              <div className='form_pag_cont' >
                <input type='date' value={formValues.data} onChange={handleChange('data')} />
              </div>


            </div>

            <div className='form_pag_field' >

              <p>OBSERVAÇÕES</p>

              <div className='form_pag_cont area-text' >

                <textarea value={formValues.observacoes} onChange={handleChange('observacoes')} ></textarea>

              </div>

            </div>

            <div className='form_pag_field' >

              <div className='form_pag_cont btn_save_pag' >

                <input type='submit' value={btnLoading ? 'CARREGANDO...' : 'SALVAR'} disabled={btnLoading} id='submit_btn' onClick={(event) => {

                  event.preventDefault();

                  save_pag();

                }} />

              </div>

            </div>

          </form>

        </div>

      </div>

      <ToastContainer
        theme='dark'
      />

    </div>
  )

}

export default OverlayPagamento;