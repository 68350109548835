import LoadingScreen from "../Componentes/LoadingScreen";

import Cookies from "js-cookie";
import axios from "axios";

import { Navigate } from "react-router-dom"
import { useState, useEffect, useCallback } from "react";
import { UseAuth } from "../Funcoes/UseAuth"
import NavBar from "../Componentes/NavBar";
import UsersBody from "../Componentes/UsersBody";
import OverlayObrasLiberadas from "../Componentes/OverlayObrasLiberadas";
import OverlayDelUser from "../Componentes/OverlayDelUser";
import OverlayEditUser from "../Componentes/OverlayEditUser";
import OverlayAddObra from "../Componentes/OverlayAddObra";

const UsersList = () => {

    const [ loadingScreen, setLoadingScreen ] = useState( true );
    const [ auth, setAuth ] = useState( null )

    const [ usuarios, setUsuarios ] = useState([]);
    const [ filterUsuarios, setFilterUsuarios ] = useState([]);

    const [ stateOverlay, setStateOverlay ] = useState( false );
    const [ userSelected, setUserSelected ] = useState( null );
    const [ userAccess, setUserAccess ] = useState( null );

    const [ overlayDel, setOverlayDel ] = useState( false );
    const [ overlayEdit, setOverlayEdit ] = useState( false );
    const [ overlayAdd, setOverlayAdd ] = useState( false );

    const token = Cookies.get('token')

    useEffect( () => {

        const fetchUser = async () => {

            try{

                setAuth( await UseAuth( token ) )

            }catch( error ){

                console.log( error )

            }finally{

                setLoadingScreen( false )

            }

        }

        fetchUser();

    }, [ token ])

    useEffect(() => {

        if( auth ){

            const search_users = async () => {

                try{

                    const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetUserList/');
                    console.log( data );

                    setUsuarios( data );
                    setFilterUsuarios( data );

                }catch( error ){

                    console.log( error )

                }

            }

            search_users();

        }

    }, [ auth ])

    if( loadingScreen ){

        return(
            <LoadingScreen />
        )

    }

    return(
        <>
            <NavBar
            />
            <UsersBody 
                data={ filterUsuarios }
                selectUser={ setUserSelected }
                changeStateOverlay={ setStateOverlay }
                changeOverlayDel={ setOverlayDel }
                changeOverlayEdit={ setOverlayEdit }
                getUserAccess={ setUserAccess }
            />
            <OverlayObrasLiberadas 
                usuario={ userSelected }
                stateOverlay={ stateOverlay }
                changeStateOverlay={ setStateOverlay }
                setOverlayAdd={ setOverlayAdd }
            />
            <OverlayAddObra
                usuario={ userSelected }
                overlayAdd={ overlayAdd }
                setOverlayAdd={ setOverlayAdd }
            />
            <OverlayEditUser
                overlayEdit={ overlayEdit }
                changeOverlayEdit={ setOverlayEdit }
                usuario={ userSelected }
                getUserAccess={ setUserAccess }
                userAccess={ userAccess }
            />
            <OverlayDelUser
                overlayDel={ overlayDel }
                changeOverlayDel={ setOverlayDel }
                usuario={ userSelected }
            />
        </>
    )

}

export default UsersList;