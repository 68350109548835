import './SideBarMenu.css';

import axios from 'axios';

import { SlLogout } from "react-icons/sl";
import { IoHome, IoPersonAddSharp, IoDocumentText } from "react-icons/io5";
import { FaMapMarkerAlt, FaFileContract } from "react-icons/fa";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { GiHealthNormal } from "react-icons/gi";
import { RiHealthBookFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

const SideBarMenu = ({ state, changeState, accessLvl, nome, matricula, funcao, obra, maoObra, status }) => {

  const navigate = useNavigate();

  const [ relatorio, setRelatorio ] = useState('GERAR RELATÓRIO');
  const [ btnRelatorio, setBtnRelatorio ] = useState( true );

  const generate_relat = async () => {

    setRelatorio('CARREGANDO...');
    setBtnRelatorio( false );

    try {
      const response = await axios.post(
          'https://sistemaintegrado.palmont.com.br/Painel/API/GenerateRelatAdm/',
          {
              nomeFilter: nome,
              matriculaFilter: matricula,
              funcaoFilter: funcao,
              obraFilter: obra,
              maoObraFilter: maoObra,
              statusFilter: status
          },
          {
              responseType: 'blob',
          }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

  } catch (error) {

      console.error("Erro ao gerar o relatório:", error);
      alert("Ocorreu um erro ao gerar o relatório. Por favor, tente novamente.");
      
  }finally{

    setBtnRelatorio( true );
    setRelatorio("GERAR RELATÓRIO");

  }

  }

  return (

    <div className={state ? "overlay_menu_show" : "overlay_menu_hide"} onClick={ () => { changeState(!state) } }>

      <div className='menuSidebar' onClick={(event) => { event.stopPropagation() }}>

        <div className='btnMenu firstBtn' onClick={() => { navigate("/Republicas") }}>

          <IoHome className='iconBtn' />
          <p> REPUBLICAS </p>

        </div>

        <div className='btnMenu' onClick={() => { navigate("/FolgaInfo") }}>
          <FaMapMarkerAlt className='iconBtn' />
          <p> FOLGA DE CAMPO </p>
        </div>

        <div className='btnMenu' onClick={() => { navigate("/ProcessoAdm") }}>
          <IoPersonAddSharp className='iconBtn' />
          <p> PROCESSO ADMISSIONAL </p>
        </div>

        <div className='btnMenu' onClick={() => { navigate("/ExamOcup") }}>
          <GiHealthNormal className='iconBtn' />
          <p> EXAMES OCUPACIONAIS </p>
        </div>

        <div className='btnMenu' onClick={() => { navigate("/Afastamentos") }}>
          <RiHealthBookFill className='iconBtn' />
          <p> AFASTAMENTOS </p>
        </div>

        <div className='btnMenu' onClick={() => { navigate("/Recisoes") }}>
          <FaFileContract className='iconBtn' />
          <p> RECISÕES </p>
        </div>

        <div className='btnMenu' onClick={() => { window.location.href = '/Painel/Atendimento/' }}>
          <BiSolidHelpCircle className='iconBtn' />
          <p> ATENDIMENTOS </p>
        </div>

        {accessLvl === 1 &&

          <div className='btnMenu' onClick={() => { navigate("/FolhaPag") }}>
            <FaMoneyCheckDollar className='iconBtn' />
            <p> FOLHA DE PAGAMENTO </p>
          </div>

        }

        <div className='btnMenu' onClick={() => {

          if( btnRelatorio ){ generate_relat() }

        }}>
          <IoDocumentText className='iconBtn' />
          <p>{ relatorio }</p>
        </div>

        <div className='footerMenu'>

          <div className='btnMenu logOut'>
            <SlLogout className='iconBtn' />
            <p>LOGOUT</p>
          </div>

        </div>

      </div>

    </div>

  );

}

export default SideBarMenu;