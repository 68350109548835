import { format } from 'date-fns';

export function formatDate(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1;
    const day = dateString.substring(6, 8);

    const dateObject = new Date(year, month, day);

    return format(dateObject, 'dd/MM/yyyy');
}