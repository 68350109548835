import { useEffect, useRef } from 'react';
import './Filters.css';

const Filters = ({ colabs, colabsReal, updateColabsReal, nome, setNome, matricula, setMatricula, funcao, setFuncao, obra, setObra, maoObra, setMaoObra, status, setStatus }) => {

  const handleFilter = (event) => {

    if (event.target.id === 'filterName') { setNome(event.target.value) }
    if (event.target.id === 'filterMat') { setMatricula(event.target.value) }
    if (event.target.id === 'filterFunc') { setFuncao(event.target.value) }
    if (event.target.id === 'obraFilter') { setObra(event.target.value) }
    if (event.target.id === 'maoFilter') { setMaoObra(event.target.value) }
    if (event.target.id === 'statusFilter') { setStatus(event.target.value) }

  };

  useEffect(() => {

    let valuesFilter = colabs;

    if (nome) { valuesFilter = valuesFilter.filter(obj => obj.NOME.toLowerCase().includes(nome.toLowerCase())) }
    if (matricula) { valuesFilter = valuesFilter.filter(obj => obj.MATRICULA.includes(matricula)) }
    if (funcao) { valuesFilter = valuesFilter.filter(obj => obj.FUNCAO === funcao) }
    if (obra) { valuesFilter = valuesFilter.filter(obj => obj.OBRA === obra) }
    if (maoObra) { valuesFilter = valuesFilter.filter(obj => obj.MAOOBRA === maoObra) }
    if (status) { valuesFilter = valuesFilter.filter(obj => obj.STATUS === status) }

    updateColabsReal( valuesFilter );

  }, [nome, matricula, funcao, obra, maoObra, status]);

  const FuncoesFilter = Array.from(new Set(colabs.map(item => item.FUNCAO)));
  const Funcoes = FuncoesFilter.sort();
  const filterFunc = useRef(null);

  const ObrasFilter = Array.from(new Set(colabs.map(item => item.OBRA)));
  const Obras = ObrasFilter.sort();
  const obraFilter = useRef(null);

  return (
    <div className='headerContent'>
      <div className='filtersDiv'>
        <div className='filterRow1'>
          <input id='filterName' className='filterName' placeholder='COLABORADOR:' onChange={handleFilter} value={nome} />
          <input id='filterMat' className='filterMat' placeholder='MATRICULA:' onChange={handleFilter} value={matricula} />
          <select id='filterFunc' ref={filterFunc} className='filterFunc' onChange={handleFilter} value={funcao}>
            <option value={""}> FUNÇÃO... </option>
            {Funcoes.map((obj, index) => (
              <option key={index} value={obj}>{obj}</option>
            ))}
          </select>
        </div>
        <div className='filterRow2'>
          <select id='obraFilter' ref={obraFilter} className='obraFilter' onChange={handleFilter} value={obra}>
            <option value={""}> CENTRO CUSTO... </option>
            {Obras.map((obj, index) => (
              <option key={index} value={obj}>{obj}</option>
            ))}
          </select>
          <select id='maoFilter' className='maoFilter' onChange={handleFilter} value={maoObra}>
            <option value={""}> MÃO DE OBRA... </option>
            <option value={"D"}> DIRETA </option>
            <option value={"I"}> INDIRETA </option>
          </select>
          <select id='statusFilter' className='statusFilter' onChange={handleFilter} value={status}>
            <option value={""}> STATUS... </option>
            <option value={"AFASTADO"}> AFASTADO </option>
            <option value={"ATIVO"}> ATIVO </option>
            <option value={"DESLIGADO"}> DESLIGADO </option>
            <option value={"FÉRIAS"}> FÉRIAS </option>
          </select>
        </div>
      </div>
      <div className='statsInfo'>
        <p className='colabsGeral'> COLABORADORES: {colabsReal.length} </p>
        <p className='colabstatus1'> ATIVOS: {colabsReal.filter(obj => obj.STATUS === "ATIVO").length} - DESLIGADOS: {colabsReal.filter(obj => obj.STATUS === "DESLIGADO").length} </p>
        <p className='colabstatus2'> AFASTADOS: {colabsReal.filter(obj => obj.STATUS === "AFASTADO").length} - FÉRIAS: {colabsReal.filter(obj => obj.STATUS === "FÉRIAS").length} </p>
        <p className='colabsMO'> DIRETO: {colabsReal.filter(obj => obj.MAOOBRA === "D").length} - INDIRETO: {colabsReal.filter(obj => obj.MAOOBRA === "I").length} </p>
      </div>
    </div>
  );
}

export default Filters;