import ColabInfo from '../ColabInfo';
import ColabsStats from '../ColabStats';
import './ColabInfoContent.css'

const ColabInfoContent = ({ user, colab }) => {

    return(

        <div className='ColabInfoContent' >
            
            <ColabInfo data={colab} />
            {colab.length > 0 ? <ColabsStats data={colab} user={ user } /> : 'Carregando...'}

        </div>

    )

}

export default ColabInfoContent;