import { formatarValor } from '../../Funcoes/FormatarValor';
import './PaymentSummary.css';

import { useState, useEffect } from 'react';

const PaymentSummary = ({ folhaValues }) => {

    const [ somaValue, setSomaValue ] = useState( null )

    return( 
        <>
            <div className="summaryBox" >
                <span className="summaryText" >Total: </span>
                <span className="summaryAmount" >R$ { somaValue }</span>
            </div>
        </>
    );

}

export default PaymentSummary;