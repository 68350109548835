import './LiderByObra.css'

import axios from 'axios'
import { useState, useEffect } from 'react'

const LiderByObra = ({ obra, liderState, changeLiderState }) => {

    const [ lideres, setLideres ] = useState([])

    useEffect( () => {

        const getColabs = async () => {

            try{

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getLideres.php')

                let lideres = data.filter( obj => obj.Obra === obra )

                setLideres( lideres )

            }catch( error ){

                console.log( error )

            }

        }

        getColabs();

    }, [ obra ])

    return(
        <select value={ liderState } onChange={ changeLiderState } >

            <option value={''} >SELECIONE...</option>

            { lideres.map( obj => (

                <option key={obj.RA_CIC} value={obj.RA_NOMECMP} >{obj.RA_NOMECMP}</option>

            ) ) }

        </select>
    )

}

export default LiderByObra;