import { useEffect, useState } from 'react';
import './RepList.css';
import axios from 'axios';

const RepList = ({ identifier, showState, changeShowState }) => {

    const [ colabsList, setColabsList ] = useState([]);
    const [ loading, setLoading ] = useState( false );

    useEffect(() => {

        const searchList = async () => {

            setLoading( true );

            try{

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetRepList/', {
                    identifier : identifier
                })
    
                console.log( data );
    
                setColabsList( data );

            }catch( error ){

                console.log( error )
    
            }finally{

                setLoading( false );
                
            }

        }

        if( identifier ){

            searchList();

        }

    }, [ identifier ])

    return(
        <div className={ showState ? 'overlayRepList show' : 'overlayRepList hide' } onClick={ () =>{ changeShowState( false ) } } >
            <div className='squareRepList' onClick={ ( e ) => { e.stopPropagation(); } } >
                { identifier ? ( 
                    <table>
                        <thead>
                            <tr>
                                <th>NOME</th>
                                <th>MATRICULA</th>
                                <th>CPF</th>
                                <th>FUNÇÃO</th>
                                <th>CENTRO CUSTO</th>
                            </tr>
                        </thead>
                        <tbody>
                            { loading ? (
                                <tr>
                                    <td colSpan={ 5 }>Carregando...</td>
                                </tr>
                            ) : colabsList.length > 0 ?
                                colabsList.map( ( obj ) => (
                                    <tr key={ obj.id } >
                                        <td>{ obj.nome }</td>
                                        <td>{ obj.matricula }</td>
                                        <td>{ obj.cpf }</td>
                                        <td>{ obj.funcao }</td>
                                        <td>{ obj.centro_custo }</td>
                                    </tr> )
                                ) : ( 
                                    <tr>
                                        <td colSpan={ 5 } > NENHUM COLABORADOR ALOCADO NA REPUBLICA SELECIONADA </td>
                                    </tr> 
                                ) }
                        </tbody>
                    </table> 
                ) : (
                    <p> Erro ao encontrar republica!!! </p> ) }
            </div>
        </div>
    )

}

export default RepList;