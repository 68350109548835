import axios from 'axios';
import style from './Formulario.module.css';
import 'react-toastify/dist/ReactToastify.css';

const Formulario = ({ children, valores, exames, setLoadingSave, formFunc, refreshList, changeState, user }) => {

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoadingSave(true);

        const values = { ...valores };
        const arr_exames = exames
            .filter(exame => exame.check)
            .map(exame => exame.exam);

        values.exames = arr_exames;
        values.user = user

        try {

            let query = formFunc === "create" ? "https://sistemaintegrado.palmont.com.br/Painel/API/SaveASO/" : "https://sistemaintegrado.palmont.com.br/Painel/API/EditAso/"

            const { data } = await axios.post( query , values );
            console.log( data );

            if (data.STATUS === "SUCCESS") {

                changeState( false );
                refreshList();

            }

        } catch (error) {

            console.log(error);

        } finally {

            setLoadingSave(false);

        }

    }

    return (
        <div className={style.formDiv}>

            <form onSubmit={handleSubmit}>

                {children}
                <input type='submit' value='SALVAR' />

            </form>

        </div>

    );

}

export default Formulario;