import Filters from '../Filters';
import SideBarMenu from '../SideBarMenu';
import TabelaColabs from '../TabelaColabs';
import './Content.css'

import axios from "axios";
import React, { useState, useEffect } from 'react';

const Content = ({ state, changeState, accessLvl }) => {

  const [colabs, setColabs] = useState([]);
  const [colabsReal, setColabsReal] = useState([]);

  const [nome, setNome] = useState('');
  const [matricula, setMatricula] = useState('');
  const [funcao, setFuncao] = useState('');
  const [obra, setObra] = useState('');
  const [maoObra, setMaoObra] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/assets/php/get_colabs.php");
        const data = response.data;

        console.log(data);

        setColabs(data);
        setColabsReal(data);

      } catch (error) {

        console.log(error);

      }

    };

    fetchData();
    
  }, []);

  return (
    <div className='content_body'>
      <Filters 
        colabs={colabs} 
        colabsReal={colabsReal} 
        updateColabsReal={setColabsReal} 
        nome={nome} 
        setNome={setNome} 
        matricula={matricula} 
        setMatricula={setMatricula} 
        funcao={funcao} 
        setFuncao={setFuncao} 
        obra={obra} 
        setObra={setObra} 
        maoObra={maoObra} 
        setMaoObra={setMaoObra} 
        status={status} 
        setStatus={setStatus} 
      />
      <TabelaColabs colabs={colabsReal} />
      <SideBarMenu 
        state={state} 
        changeState={changeState} 
        accessLvl={accessLvl} 
        nome={nome} 
        matricula={matricula} 
        funcao={funcao} 
        obra={obra} 
        maoObra={maoObra} 
        status={status} 
      />
    </div>
  );
}

export default Content;
