import BodyContainer from '../BodyContainer';
import HeaderContainer from '../HeaderContainer';
import style from './Exams.module.css';

const ExamsBody = ({ dados, changeFormulario, showOverlay, changeFormFunc, setExamList, examList, onFilter, loading }) => {

    return(
        <div className={ style.examBody } >

            <HeaderContainer dados={ dados } onFilter={ onFilter } />
            <BodyContainer dados={ dados } changeFormulario={ changeFormulario } showOverlay={ showOverlay } changeFormFunc={ changeFormFunc } examList={ examList } setExamList={ setExamList } onFilter={ onFilter } loading={ loading }  />

        </div>
    )

}

export default ExamsBody;