import { formatMoney } from "../../Funcoes/FormatMoney";

const InputMoney = ({ valorState , changeValorState }) => {

  return (

    <input 
      type="text" 
      value={ valorState } 
      onChange={ ( event ) => {

        console.log( formatMoney( event.target.value ) )
        changeValorState( event )

      } }
      placeholder="R$ 0,00" />

  );

};

export default InputMoney;