import UsersSquare from '../UsersSquare';
import './UsersBody.css';

const UsersBody = ({ data, selectUser, changeStateOverlay, changeOverlayDel, changeOverlayEdit, getUserAccess }) => {

    return(

        <div className='usersBody' >

            <UsersSquare data={ data } selectUser={ selectUser } changeStateOverlay={ changeStateOverlay } changeOverlayDel={ changeOverlayDel } changeOverlayEdit={ changeOverlayEdit } getUserAccess={ getUserAccess } />

        </div>

    )

}

export default UsersBody;